import {EmailTemplateHttpService} from "@/server/services/EmailTemplateHttpService";
import {server} from "@/server/http-common";
import {EmailTemplateEntity} from "@/model/entities/EmailTemplateEntity";
import EmailTemplateMapper from "@/model/mapper/EmailTemplateMapper";

export class DefaultEmailTemplateHttpService implements EmailTemplateHttpService {
    private server = server;
    private defaultApiPrefix = "/api/v1/emails"

    public loadEmailTemplate(id: Number): Promise<EmailTemplateEntity> {
        return this.server.get(`${this.defaultApiPrefix}/templates/${id}`)
            .then(response => {
                return EmailTemplateMapper.fromRegularObject(response.data);
            });
    }

    public loadTemplatePreview(emailTemplate: EmailTemplateEntity): Promise<String> {
        emailTemplate.modules.forEach((element, index) => element.position = index);
        return this.server.post(`${this.defaultApiPrefix}/preview`, EmailTemplateMapper.toRegularObject(emailTemplate))
            .then((response) => {
                return response.data
            })
    }

    public saveTemplate(emailTemplate: EmailTemplateEntity): Promise<any> {
        return this.server.post(this.defaultApiPrefix, EmailTemplateMapper.toRegularObject(emailTemplate));
    }

    public updateTemplate(id: Number, emailTemplate: EmailTemplateEntity): Promise<any> {
        return this.server.put(`${this.defaultApiPrefix}/${id}`, EmailTemplateMapper.toRegularObject(emailTemplate));
    }

    public loadAllTemplates(): Promise<EmailTemplateEntity[]> {
        return this.server.get(`${this.defaultApiPrefix}/templates`)
            .then(response => {
                return Array.from(response.data, (template) => EmailTemplateMapper.fromRegularObject(template as any))
            });
    }
}
