import {UserEmailEntity} from "@/model/entities/UserEmailEntity";
import EmailTemplateMapper from "@/model/mapper/EmailTemplateMapper";

export class UserEmailMapper {
    public fromRegularObject(email: any): UserEmailEntity {
        const userEmailEntity: UserEmailEntity = new UserEmailEntity();
        userEmailEntity.name = email.name;
        userEmailEntity.id = email.id;
        userEmailEntity.email = email.email;
        userEmailEntity.template = EmailTemplateMapper.fromRegularObject(email.template);
        return userEmailEntity;
    }

    public toRegularObject(userEmailEntity: UserEmailEntity) {
        return {
            id: userEmailEntity.id,
            name: userEmailEntity.name,
            email: userEmailEntity.email,
            template: EmailTemplateMapper.toRegularObject(userEmailEntity.template)
        }
    }
}