
import {Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import {PropType} from "vue";

/**
 * Proxy to element plus input.
 * Component doesn't require 'v-model:value'. Initialized with empty string by default.
 * Can be initialized with any other String with prop :default-value.
 */
export default class EmptyValueInput extends Vue {

    private value: String = "";

    @Prop({type: String as PropType<String>})
    private defaultValue!: String;

    @Prop({type: String as PropType<String>})
    private placeholder: String = "";

    public mounted() {
        if (this.defaultValue) {
            this.value = this.defaultValue;
        }
    }

    @Emit("input")
    public emitInput() {
        return this.value;
    }
}

