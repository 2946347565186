import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { role: "main" }
const _hoisted_2 = { class: "album py-3" }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_LayoutPicker = _resolveComponent("LayoutPicker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_ChannelGrid = _resolveComponent("ChannelGrid")!
  const _component_ChannelTable = _resolveComponent("ChannelTable")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_row, { style: {"margin-top":"1rem"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 23 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _ctx.onCreateChannel,
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Create channel")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _ctx.onIndexAll,
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Index all")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.namePart,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.namePart) = $event)),
                  style: {"width":"240px","margin-left":"12px"},
                  placeholder: "Find channel",
                  onInput: _ctx.onChannelSearch,
                  clearable: "",
                  autofocus: ""
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_LayoutPicker, {
                  layout: _ctx.layout,
                  onLayoutChanged: _cache[1] || (_cache[1] = (event) => this.layout = event)
                }, null, 8, ["layout"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.layout === 'Card')
          ? (_openBlock(), _createBlock(_component_ChannelGrid, {
              key: 0,
              channels: _ctx.channels
            }, null, 8, ["channels"]))
          : _createCommentVNode("", true),
        (_ctx.layout === 'Table')
          ? (_openBlock(), _createBlock(_component_ChannelTable, {
              key: 1,
              channels: _ctx.channels
            }, null, 8, ["channels"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}