import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Channels from '@/views/ChannelBoard.vue'
import NotFound from '@/views/NotFound.vue';
import EmailBuilder from '@/views/email/EmailBuilder.vue';
import Statistics from '@/views/Statistics.vue';
import CreateChannel from '@/views/CreateChannel.vue'
import CreateEmailModule from "@/views/email/CreateEmailModule.vue";
import CreateEmailTemplate from "@/views/email/CreateEmailTemplate.vue";
import CategoryManagement from "@/views/CategoryManagement.vue";
import PostBoard from "@/views/PostBoard.vue";
import EditPost from "@/views/EditPost.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import {useAuth} from '@/services/auth';
import CreateEmail from "@/views/email/CreateEmail.vue";
import EditList from "@/views/EditList.vue";
import ListBoard from "@/views/ListBoard.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        props: true
    },
    {
        path: '/board',
        name: 'PostBoard',
        component: PostBoard
    },
    {
        path: '/postlist',
        name: 'ReadLists',
        component: ListBoard,
        meta: {
            authRequired: true,
            role: 'ROLE_USER'
        }
    },
    {
        path: '/category/management',
        name: 'CategoryManagement',
        component: CategoryManagement,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/post/:id',
        name: 'EditPost',
        component: EditPost,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/list',
        name: 'EditList',
        component: EditList,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/channels',
        name: 'Channels',
        component: Channels,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/createChannel',
        name: 'CreateChannel',
        component: CreateChannel,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/channels/:id',
        name: 'Channel',
        component: CreateChannel,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: '/emails/create-module',
        name: 'CreateEmailModule',
        component: CreateEmailModule,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/emails/:id',
        component: CreateEmail,
        name: 'EmailDetails',
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/emails/module/:id',
        name: 'EmailModuleDetails',
        component: CreateEmailModule,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/emails/create-template',
        name: 'CreateEmailTemplate',
        component: CreateEmailTemplate,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/emails/templates/:id',
        name: 'EmailTemplateDetails',
        component: CreateEmailTemplate,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/emails',
        name: 'EmailBuilder',
        component: EmailBuilder,
        props: true,
        meta: {
            authRequired: true,
            role: 'ROLE_EDITOR'
        }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    //catch 404
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BABEL_URL),
    routes
})

router.beforeResolve(async (to, from, next) => {
    if (to.meta.authRequired) {
        if (await useAuth().getUser() && useAuth().hasRole(to.meta.role as String)) {
            next()
        } else {
            next({name: 'Unauthorized'});
        }
    } else {
        next()
    }
})

export default router