import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    ref: "multipleTableRef",
    data: _ctx.userEmails
  }, {
    default: _withCtx(() => [
      (_ctx.selectable)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            type: "selection",
            width: "55"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, {
        label: "Name",
        property: "name"
      }),
      (_ctx.manageable)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            label: "Operations",
            align: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: ($event: any) => (_ctx.onEdit(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Edit")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                onClick: ($event: any) => (_ctx.onDelete(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Delete")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data"]))
}