
import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import ViewGridOutline from 'vue-material-design-icons/ViewGridOutline.vue';
import ViewAgendaOutline from 'vue-material-design-icons/ViewAgendaOutline.vue';
import {PropType} from "vue";

@Options({components: {ViewGridOutline, ViewAgendaOutline}, emits: ['layout-changed']})
export default class LayoutPicker extends Vue {
    @Prop({type: String as PropType<String>})
    private layout!: String;

    @Emit("layout-changed")
    setLayout(layout: String) {
        return layout;
    }
}
