import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6baafbda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-module" }
const _hoisted_2 = {
  key: 0,
  class: "dependency-row-container"
}
const _hoisted_3 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_EmptyValueInput = _resolveComponent("EmptyValueInput")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_PostTable = _resolveComponent("PostTable")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_row, { class: "title" }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.moduleDescription.module.name), 1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleDescription.module.dependencies, (dependency) => {
        return (_openBlock(), _createBlock(_component_el_row, { key: dependency }, {
          default: _withCtx(() => [
            (_ctx.moduleDescription.dependencyParams.has(dependency))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleDescription.dependencyParams.get(dependency), (dependencyId) => {
                    return (_openBlock(), _createBlock(_component_el_row, {
                      key: dependencyId,
                      class: "dependency-row"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_el_tag, { size: "small" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(dependency), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createTextVNode(" " + _toDisplayString(dependencyId), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_el_button, {
                            type: "primary",
                            plain: "",
                            size: "small",
                            onClick: ($event: any) => (_ctx.moveCardUp(dependencyId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Up ")
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_el_button, {
                            type: "primary",
                            plain: "",
                            size: "small",
                            onClick: ($event: any) => (_ctx.moveCardDown(dependencyId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Down ")
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_el_button, {
                            size: "small",
                            type: "danger",
                            onClick: ($event: any) => (_ctx.onPostDelete(dependencyId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Delete ")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textScanner.getUniqueMatches(this.moduleDescription.module.content, this.simpleVariablePattern, this.simpleVariableMapperFunction), (field) => {
                          return (_openBlock(), _createBlock(_component_el_row, {
                            key: field,
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form, {
                                "label-width": "auto",
                                style: {"width":"100%"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, {
                                    label: field + ':'
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_EmptyValueInput, {
                                        placeholder: 'Please input value',
                                        "default-value": _ctx.moduleDescription.params.get( dependencyId + '.' + field),
                                        onInput: (value) => _ctx.onFieldUpdate(dependencyId, field, value)
                                      }, null, 8, ["default-value", "onInput"])
                                    ]),
                                    _: 2
                                  }, 1032, ["label"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              size: "small",
              onClick: ($event: any) => (_ctx.openModuleDialog(dependency))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Add " + _toDisplayString(dependency), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128)),
      (_ctx.showDeleteButton)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            style: {"margin-top":"10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "danger",
                plain: "",
                onClick: _ctx.onModuleDelete,
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Delete module ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      title: "Choose posts",
      width: "600px",
      modelValue: _ctx.moduleDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.moduleDialogVisible) = $event))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_el_button, {
            type: "info",
            plain: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.moduleDialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Close")
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_PostTable, {
              "enable-resource-link": false,
              onPostSelected: _ctx.onSelectedPosts
            }, null, 8, ["onPostSelected"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}