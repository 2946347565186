import {UrlPostSearchMask} from "@/model/request/UrlPostSearchMask";
import {LocationQuery} from "vue-router";

export class UrlPostSearchFilterEncoder {
    public encodeQueryFilter(mask: UrlPostSearchMask) : LocationQuery {
        return {
            tags: encodeURIComponent(mask.tags.toString()),
            recommended: mask.recommended.toString(),
            liked: mask.liked.toString(),
            bookmarked: mask.bookmarked.toString(),
            sortBy: mask.sortBy,
            listId: String(mask.listId)
        };
    }

    public decodeQueryFilter(query: LocationQuery) : UrlPostSearchMask {
        const mask = new UrlPostSearchMask();
        if (query.tags) {
            mask.tags = decodeURIComponent(query.tags.toString()).split(',')
        }

        if (query.sortBy && query.sortBy === 'popular') {
            mask.sortBy = query.sortBy;
        }

        if (query.liked && query.liked === 'true') {
            mask.liked = true
        }

        if (query.recommended && query.recommended === 'true') {
            mask.recommended = true;
        }

        if (query.bookmarked && query.bookmarked === 'true') {
            mask.bookmarked = true;
        }

        if (query.listId && query.listId !== 'null') {
            mask.listId = Number.parseInt(query.listId.toString());
        }

        return mask;
    }
}