
import {Options, Vue} from "vue-class-component";
import {InjectReactive, Prop, Watch} from "vue-property-decorator";
import {PropType} from "vue";
import PostControlPanel from "@/components/post/PostControlPanel.vue";
import PostGrid from "@/components/post/PostGrid.vue";
import {postEmitter, STATUS_UPDATED} from "@/events/PostEventBus";
import SectionTitle from "@/components/commons/SectionTitle.vue";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {PostHttpService} from "@/server/services/impl/PostHttpService";
import DefaultPostTable from "@/components/post/DefaultPostTable.vue";

@Options({components: {PostControlPanel, PostGrid, SectionTitle, DefaultPostTable}})
export default class LatestPostBoard extends Vue {
    private postService: PostHttpService = new PostHttpService();
    //Init with empty posts for displaying loading skeleton
    private userPosts: Array<UserPostEntity> = new Array(4).fill(null);

    @Prop({type: Object as PropType<URLSearchParams>})
    private requestedOptions!: URLSearchParams;

    private layout: 'Card' | 'Table' = 'Card';

    @InjectReactive('authenticated')
    private userAuthenticated!: Boolean;

    private titlePattern: String = "";

    private totalPostCount: Number = 0;
    private currentPageNumber: Number = 1;
    private pageSizes: Number[] = [12, 24, 48]
    private currentPageSize: Number = this.pageSizes[1];


    private periodChanged(period) {
        const options = new URLSearchParams(this.requestedOptions);
        options.set("period", period.toString());
        this.loadCompactBoard(this.currentPageNumber, this.currentPageSize, this.titlePattern, options);
    }

    @Watch('requestedOptions')
    private loadCurrentPage(): void {
        this.loadCompactBoard(this.currentPageNumber, this.currentPageSize, this.titlePattern, this.requestedOptions);
    }

    public mounted(): void {
        postEmitter.on(STATUS_UPDATED, (event) => {
            const entity = event as UserPostEntity;
            const position = this.userPosts.findIndex(userPost => userPost.post.id === entity.post.id);
            if (position !== -1) {
                this.userPosts.splice(position, 1, entity)
            }
        })
        this.loadCurrentPage();
    }

    private loadCompactBoard(page: Number, pageSize: Number, pattern: String, params?: URLSearchParams): Promise<any> {
        return this.postService.loadPosts({page: page, size: pageSize, pattern: pattern, listId: null}, params)
            .then(response => {
                this.userPosts = response.data.content;
                this.totalPostCount = response.data.totalItemsCount;
                return response;
            })
    }

    onCurrentPageChange() {
        this.loadCurrentPage();
        this.scrollToTop();
    }

    scrollToTop() {
        //TODO do something smarter here
        window.scrollTo(0, 550);
    }

}
