
import {shallowRef} from "vue";
import {Options, Vue} from "vue-class-component";
import TrashCanOutline from 'vue-material-design-icons/TrashCanOutline.vue'
import PlaylistEdit from 'vue-material-design-icons/PlaylistEdit.vue'
import ShareVariantOutline from 'vue-material-design-icons/ShareVariantOutline.vue'
import Email from 'vue-material-design-icons/Email.vue'
import Heart from 'vue-material-design-icons/Heart.vue';
import Bookmark from 'vue-material-design-icons/Bookmark.vue';
import TrendingUp from 'vue-material-design-icons/TrendingUp.vue';
import FormatListBulleted from 'vue-material-design-icons/FormatListBulleted.vue';
import {LocationQuery} from "vue-router";
import {UserNewsListsHttpService} from "@/server/services/UserNewsListsHttpService";
import {DefaultNewsListsHttpService} from "@/server/services/impl/DefaultNewsListsHttpService";
import {ElMessage} from "element-plus";
import {useAuth} from "@/services/auth";


@Options({
    components: {
        TrashCanOutline,
        ShareVariantOutline,
        Email,
        PlaylistEdit,
        Heart,
        Bookmark,
        TrendingUp,
        FormatListBulleted
    }
})
export default class ListBoard extends Vue {
    private newsListHttpService: UserNewsListsHttpService = new DefaultNewsListsHttpService();
    private tableItems: Array<ListTableEntity> = [];
    private activeDeleteDialogIdsSet = new Set<Number>();

    private defaultLists: Array<ListTableEntity> = [
        new ListTableEntity(-1, "Liked posts", false, false, false, {
            sortBy: 'latest',
            recommended: 'false',
            bookmarked: 'false',
            liked: 'true',
            tags: ''
        }, shallowRef(Heart)),
        new ListTableEntity(-1, "Recommended posts", false, false, false, {
            sortBy: 'latest',
            recommended: 'true',
            bookmarked: 'false',
            liked: 'false',
            tags: ''
        }, shallowRef(TrendingUp)),
        new ListTableEntity(-1, "Bookmarked posts", false, false, false, {
            sortBy: 'latest',
            recommended: 'false',
            bookmarked: 'true',
            liked: 'false',
            tags: ''
        }, shallowRef(Bookmark))
    ];

    mounted() {
        this.newsListHttpService.getCurrentUserNewsLists(0, 100)
            .then(lists => lists.map(list => new ListTableEntity(
                list.id,
                list.name,
                true,
                false,
                true,
                {listId: list.id.toString(), sortBy: 'latest', recommended: 'false', liked: 'false', tags: ''})))
            .then(tableItems => this.tableItems = this.defaultLists.concat(tableItems));
    }

    private copyLinkToClipboard(tableEntity: ListTableEntity) {
        const relativeUrl = this.$router.resolve({path: '/board', query: tableEntity.query});
        navigator.clipboard.writeText(`${window.location.host}${relativeUrl.fullPath}`);
        ElMessage({message: "Share link is copied to clipboard", type: "success"});
    }

    private removeUserList(tableEntity: ListTableEntity) {
        this.newsListHttpService.deleteNewsList(tableEntity.listId)
            .then(() => ElMessage({message: `Readlist with name ${tableEntity.title} was deleted`, type: "success"}))
            .then(() => this.tableItems = this.tableItems.filter(value => value.listId !== tableEntity.listId));
    }

    private deleteDialogActive(tableEntity: ListTableEntity): boolean {
        return this.activeDeleteDialogIdsSet.has(tableEntity.listId);
    }

    private hasRole(role: string): Boolean {
      return useAuth().hasRole(role)
    }
}

class ListTableEntity {
    listId: Number;
    title: String;
    deleteEnabled: Boolean;
    editEnabled: Boolean;
    shareEnabled: Boolean;
    query: LocationQuery;
    icon?: Object;

    constructor(id: Number, title: String, deleteEnabled: Boolean, editEnabled: Boolean, shareEnabled: Boolean, query: LocationQuery, icon?: Object) {
        this.listId = id;
        this.title = title;
        this.deleteEnabled = deleteEnabled;
        this.editEnabled = editEnabled;
        this.shareEnabled = shareEnabled;
        this.query = query;
        this.icon = icon;
    }
}
