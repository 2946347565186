import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_vue_cookie_accept_decline = _resolveComponent("vue-cookie-accept-decline")!

  return (_openBlock(), _createBlock(_component_vue_cookie_accept_decline, {
    disableDecline: true,
    elementId: "cookiePolicyPanel",
    position: "bottom",
    transitionName: "slideFromBottom"
  }, {
    message: _withCtx(() => [
      _createVNode(_component_el_text, { class: "mx-1" }, {
        default: _withCtx(() => [
          _createTextVNode("To maximize your browsing experience, we use cookie technology to help make the experience better. Cookies are small text files that allow us to enhance the browsing experience. You can change your cookie settings at any time. Find out more about cookies and how to manage your privacy by reviewing our ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_link, {
        href: "/privacy-policy",
        type: "primary",
        target: "_blank"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Privacy Policy")
        ]),
        _: 1
      })
    ]),
    acceptContent: _withCtx(() => [
      _createTextVNode("OK")
    ]),
    _: 1
  }))
}