import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "column-content-wrapper" }
const _hoisted_3 = { class: "preview-container" }
const _hoisted_4 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailModuleBuilder = _resolveComponent("EmailModuleBuilder")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_EmailModuleItem = _resolveComponent("EmailModuleItem")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_EmailModuleBuilder, { onModuleUpdated: _ctx.emailModuleUpdateHandler }, null, 8, ["onModuleUpdated"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(" Module Preview "),
              _createVNode(_component_EmailModuleItem, {
                "module-description": _ctx.moduleDescription,
                onModuleUpdate: _ctx.renderModulePreview
              }, null, 8, ["module-description", "onModuleUpdate"]),
              _createTextVNode(" Rendered Module Preview "),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("iframe", { srcdoc: _ctx.previewString }, null, 8, _hoisted_4)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}