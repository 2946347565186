
import {Options, Vue} from "vue-class-component";
import {ElTable, ElTableColumn} from "element-plus"
import {EmailTemplateEntity} from "@/model/entities/EmailTemplateEntity";
import {server} from "@/server/http-common";
import {Emit, Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {EmailTemplateHttpService} from "@/server/services/EmailTemplateHttpService";
import {DefaultEmailTemplateHttpService} from "@/server/services/impl/DefaultEmailTemplateHttpService";

@Options({components: {ElTable, ElTableColumn}})
export default class EmailTemplateTable extends Vue {
    private emailTemplateHttpService: EmailTemplateHttpService = new DefaultEmailTemplateHttpService();
    private templates: EmailTemplateEntity[] = [];
    private selectedTemplate: EmailTemplateEntity = {} as EmailTemplateEntity;
    private confirmDialogVisible: Boolean = false;

    @Prop({type: Boolean as PropType<Boolean>})
    private manageable: Boolean = false;

    @Prop({type: Boolean as PropType<Boolean>})
    private multipleSelect: Boolean = false;

    @Prop({type: Boolean as PropType<Boolean>})
    private singleSelect: Boolean = false;

    public mounted(): void {
        this.loadEmailTemplates();
    }

    @Emit("row-selected")
    public handleCurrentChange(val: EmailTemplateEntity) {
        return val
    }

    private loadEmailTemplates(): void {
        this.emailTemplateHttpService.loadAllTemplates().then(templates => this.templates = templates);
    }

    private onEdit(index: number, entity: EmailTemplateEntity): void {
        this.$router.push({name: 'EmailTemplateDetails', params: {id: entity.id as number}});
    }

    private onDelete(index: number, entity: EmailTemplateEntity): void {
        server.delete("/api/v1/emails/templates/" + entity.id)
            .then(response => {
                if (response.status == 200) {
                    this.templates.splice(index, 1);
                }
            });
    }

    private closeModuleDialog(): void {
        this.confirmDialogVisible = false;
        this.selectedTemplate = {} as EmailTemplateEntity;
    }

}


