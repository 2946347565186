

import {server} from "@/server/http-common";
import {ElMessage} from "element-plus";
import {Options, Vue} from "vue-class-component";
import Channel from "@/components/channel/Channel.vue";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import ChannelGrid from "@/components/channel/ChannelGrid.vue";
import LayoutPicker from "@/components/commons/LayoutPicker.vue";
import ChannelTable from "@/components/channel/ChannelTable.vue";
import {ChannelHttpService} from "@/server/services/impl/ChannelHttpService";

@Options({components: {Channel, ChannelGrid, LayoutPicker, ChannelTable}})
export default class ChannelBoard extends Vue {
    private channelHttpService = new ChannelHttpService();

    private channels: Array<ChannelEntity> = [];
    private namePart: String = "";
    private layout: String = 'Card';

    private onChannelSearch(value: String) {
      this.channelHttpService.getChannelsByQuery(value).then(channels => this.channels = channels);
    }

    private onCreateChannel() {
        this.$router.push({path: '/createChannel'})
    }

    private onIndexAll() {
        server.post("/api/v1/channels/index")
            .then(() => ElMessage({message: 'All channels were put in queue for indexing', type: "success"}))
    }

    public mounted() {
        server.get("/api/v1/channels")
            .then(response => {
                this.channels = response.data;
            })
            .catch(err => console.error(err.message))
    }
}
