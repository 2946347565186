
import {Options, Vue} from "vue-class-component";
import TagsBoard from "@/components/commons/TagsBoard.vue";
import CategoryBoard from "@/components/commons/CategoryBoard.vue";
import {TagSetHttpService} from "@/server/services/impl/TagSetHttpService";
import {TagHttpService} from "@/server/services/impl/TagHttpService";
import {TagSet} from "@/model/entities/TagSet";
import {Tag} from "@/model/entities/Tag";
import {Emit} from "vue-property-decorator";

@Options({components: {TagsBoard, CategoryBoard}})
export default class TagFilter extends Vue {
    private tagSetHttpService: TagSetHttpService = new TagSetHttpService();
    private tagHttpService: TagHttpService = new TagHttpService();

    private availableCategories: Array<TagSet> = new Array<TagSet>();
    private activeCategories: Set<String> = new Set<String>();
    private activeTags: Array<Tag> = [];

    @Emit("tag-updated")
    private emitTags(): Array<String> {
        const tags = Array.from(this.activeTags, tag => tag.name)
        let query = {};
        if (tags.length) {
          query = {tags: encodeURIComponent(tags.toString())}
        }
        this.$router.push({path: `${this.$route.path}`, query: query})
        return tags;
    }

    public mounted() {
        if (this.$route.query.tags) {
            const tagNames: Array<String> = decodeURIComponent(this.$route.query.tags.toString()).split(",");
            this.tagHttpService.getTagsWithNames(tagNames)
                .then(response => this.activeTags = response.data)
                .then(this.emitTags)
                .then(this.recalculateActiveCategories);
        }

        this.tagSetHttpService.getAllSets()
            .then(response => this.availableCategories = response.data)
            .then(this.recalculateActiveCategories);
    }

    private onTagsUpdated() {
        this.recalculateActiveCategories();
        this.emitTags();
    }

    private onCategorySelect(category: TagSet) {
        this.activeCategories.add(category.name);
        const activeTagNamesSet: Set<String> = new Set(Array.from(this.activeTags, tag => tag.name))
        category.tags.forEach(tag => {
            if (!activeTagNamesSet.has(tag.name)) {
                activeTagNamesSet.add(tag.name)
                this.activeTags.push(tag)
            }
        });
        this.emitTags();
    }

    private onCategoryRemove(category: TagSet) {
        category.tags.forEach(tag => {
            const index: number = this.activeTags.findIndex(active => active.name === tag.name);
            this.activeTags.splice(index, 1);
        });
        this.recalculateActiveCategories();
        this.emitTags();
    }

    private recalculateActiveCategories() {
        this.activeCategories.clear()
        const activeTagNames: Set<String> = new Set(Array.from(this.activeTags, tag => tag.name));
        this.availableCategories.forEach(category => {
            const active: boolean = category.tags.every(tag => activeTagNames.has(tag.name));
            if (active) {
                this.activeCategories.add(category.name);
            }
        })
    }

    private querySearchTags(queryString: string, cb: any) {
        const selectedTagNamesSet = new Set(Array.from(this.activeTags, tag => tag.name))
        this.tagHttpService.getTagsNamesByQuery(queryString)
            .then(response => cb(response.data.filter(tag => !selectedTagNamesSet.has(tag.name))));
    }
}
