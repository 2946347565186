import {server} from "@/server/http-common";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import {Option} from "@/model/entities/Option";
import {EmailModuleDescription} from "@/model/entities/EmailModuleDescription";
import {EmailModuleHttpService} from "@/server/services/EmailModuleHttpService";
import {EmailModuleDescriptionMapper} from "@/model/mapper/EmailModuleDescriptionMapper";

export class DefaultEmailModuleHttpService implements EmailModuleHttpService {
    private server = server;
    private defaultApiPath = "/api/v1/emails/modules"

    public loadModule(id: Number): Promise<EmailModuleEntity> {
        return this.server.get(`${this.defaultApiPath}/${id}`)
            .then(response => {
                return response.data;
            });
    }

    public loadModules(): Promise<Array<EmailModuleEntity>> {
        return this.server.get(this.defaultApiPath)
            .then(response => {
                return response.data
            })
    }

    public loadModuleDependenciesProperties(dependencies: Array<String>): Promise<Array<Option>> {
        return this.server.get("/api/v1/props/", {params: {dependencies: dependencies.toString()}})
            .then(response => {
                return response.data;
            });
    }

    public loadEmailModulePreview(moduleDescription: EmailModuleDescription): Promise<String> {
        return this.server.post(`${this.defaultApiPath}/render`, EmailModuleDescriptionMapper.toRegularObject(moduleDescription))
            .then(response => {
                return response.data;
            });
    }
}