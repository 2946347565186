

import {Options, Vue} from "vue-class-component";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

@Options({
  components: {
    VueCookieAcceptDecline
  }
})
export default class CookiePolicy extends Vue {
}
