import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77f77a93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { id: "preview" }
const _hoisted_3 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailTemplateBuilder = _resolveComponent("EmailTemplateBuilder")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_EmailTemplateBuilder, { onUpdatePreview: _ctx.updatePreview }, null, 8, ["onUpdatePreview"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 14 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("iframe", {
                class: "preview-container",
                srcdoc: _ctx.preview
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}