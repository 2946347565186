

import {Options, Vue} from "vue-class-component";
import {Emit, Prop, Watch,} from "vue-property-decorator";
import {PropType} from "vue";
import {server} from "@/server/http-common";
import {Option} from "@/model/entities/Option";
import LayoutPicker from "@/components/commons/LayoutPicker.vue";

@Options({components: {LayoutPicker}, emits: ['period-changed', 'layout-changed']})
export default class SectionTitle extends Vue {
    @Prop({type: String as PropType<String>})
    private title: String = "";

    @Prop({type: String as PropType<String>})
    private routerLink!: String;

    @Prop({type: Object as PropType<any>})
    private queryObject: any;

    @Prop({type: Boolean as PropType<Boolean>})
    private enablePeriodSelect: Boolean = false;

    @Prop({type: Boolean as PropType<Boolean>})
    private enableChangeLayout: Boolean = false;

    @Prop({type: Boolean as PropType<Boolean>})
    private enableSorting: Boolean = false;

    @Prop({type: String})
    private layout?: String;

    @Prop({type: String})
    private defaultActiveSorting?: String;

    private availablePeriods: Array<Option> = [];
    private activePeriod: String = "";

    private activeSorting: String = "latest";
    private availableSorting: Array<String> = ['popular', 'latest'];


    public mounted() {
        if (this.defaultActiveSorting) {
            this.activeSorting = this.defaultActiveSorting;
        }

        if (this.enablePeriodSelect) {
            this.fetchPeriods();
        }
    }

    @Watch("defaultActiveSorting")
    private changeDefaultActiveSorting(value: String) {
        this.activeSorting = value;
    }

    public fetchPeriods(): void {
        server.get("/api/v1/period").then(
            response => {
                this.availablePeriods = response.data;
                if (this.availablePeriods.length > 0) {
                    this.activePeriod = this.availablePeriods[this.availablePeriods.length - 1].value;
                    return this.activePeriod;
                }
            })
    }

    @Emit("period-changed")
    private emitPeriod(period: String): String {
        return period;
    }
    @Emit("layout-changed")
    private setLayout(layoutType) {
        return layoutType;
    }

    @Emit("sorting-changed")
    private emitSorting(sorting: String) : String {
        return sorting
    }

    @Watch("activePeriod")
    private changePeriodEvent() {
        this.emitPeriod(this.activePeriod);
    }

    @Watch("activeSorting")
    private changeActiveSorting() {
        this.emitSorting(this.activeSorting);
    }

}
