
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import ChannelControlPanel from "@/components/channel/ChannelControlPanel.vue";

@Options({components: {ChannelControlPanel}})
export default class ChannelTable extends Vue {

    @Prop({type: Object as PropType<Array<ChannelEntity>>})
    private channels: Array<ChannelEntity> = [];

}
