import {UserNewsListsHttpService} from "@/server/services/UserNewsListsHttpService";
import {NewsList} from "@/model/entities/NewsList";
import {server} from "@/server/http-common";
import {NewsListPostState} from "@/model/entities/NewsListPostState";

export class DefaultNewsListsHttpService implements UserNewsListsHttpService {
    private defaultApiPrefix: String = "/api/v1/newslist"
    private server = server;

    addPostToNewsList(listId: Number, postId: Number): Promise<any> {
        return this.server.put(`${this.defaultApiPrefix}/${listId}/post/${postId}/add`);
    }

    createNewsList(name: String): Promise<any> {
        return this.server.post(this.defaultApiPrefix.toString(), null, {params: {name: name}});
    }

    deleteNewsList(listId: Number): Promise<any> {
        return this.server.delete(`${this.defaultApiPrefix}/${listId}`);
    }

    deletePostFromNewsList(listId: Number, postId: Number): Promise<any> {
        return this.server.delete(`${this.defaultApiPrefix}/${listId}/post/${postId}/delete`);
    }

    getCurrentUserNewsLists(page: Number, size: Number): Promise<Array<NewsList>> {
        return this.server.get(this.defaultApiPrefix.toString(), {params: {page: page, size: size}})
            .then(response => response.data.content);
    }

    getCurrentUserNewsListsPostStatus(page: Number, size: Number, postId: Number): Promise<Array<NewsListPostState>> {
        return this.server.get(`${this.defaultApiPrefix}/post/${postId}`, {params: {page: page, size: size}})
            .then(response => response.data.states.content);
    }

}