import {server} from "@/server/http-common";
import {PostSearchCriteria} from "@/model/entities/PostSearchCriteria";
import {PostEntity} from "@/model/entities/PostEntity";

export class PostHttpService {
    private defaultApiPrefix: String = "/api/v1/posts"

    public loadPosts(searchCriteria: PostSearchCriteria, options?: URLSearchParams): Promise<any> {
        const httpOptions: URLSearchParams = (options) ? new URLSearchParams(options) : new URLSearchParams();

        httpOptions.set("page", (searchCriteria.page.valueOf() - 1).toString());
        httpOptions.set("size", searchCriteria.size.toString());

        if (searchCriteria.pattern) {
            httpOptions.set("pattern", searchCriteria.pattern.toString());
        }

        if (searchCriteria.period) {
            httpOptions.set("period", searchCriteria.period.toString());
        }

        if (searchCriteria.tags) {
            httpOptions.set("tags", searchCriteria.tags.toString())
        }

        if (searchCriteria.recommended) {
            httpOptions.set("recommended", searchCriteria.recommended.toString())
        }

        //TODO
        if (searchCriteria.popular) {
            httpOptions.set("popular", searchCriteria.popular.toString())
        }

        if (searchCriteria.listId) {
            httpOptions.set("listId", searchCriteria.listId.toString());
        }

        if (searchCriteria.bookmarked) {
            httpOptions.set("bookmarked", searchCriteria.bookmarked.toString());
        }

        if (searchCriteria.liked) {
            httpOptions.set("liked", searchCriteria.liked.toString());
        }

        return server.get(this.defaultApiPrefix.toString(), {params: httpOptions})
    }

    public loadPost(id: Number): Promise<PostEntity> {
        return server.get(`${this.defaultApiPrefix}/${id}`).then(response => {
            return response.data
        });
    }

    public updatePost(post: PostEntity): Promise<any> {
        return server.put(`${this.defaultApiPrefix}`, post)
    }
}