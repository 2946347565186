

import {Options, Vue} from "vue-class-component";
import {Option} from "@/model/entities/Option";
import Post from "../components/post/Post.vue"
import PostGrid from "@/components/post/PostGrid.vue";
import {ElMessage, FormInstance, FormRules} from "element-plus";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import {ChannelHttpService} from "@/server/services/impl/ChannelHttpService";
import {reactive, ref} from "vue";
import {ValidatorUtils} from "@/utils/ValidatorUtils";
import Upload from 'vue-material-design-icons/Upload.vue';
import CloudSearchOutline from 'vue-material-design-icons/CloudSearchOutline.vue';
import Undo from 'vue-material-design-icons/Undo.vue';

@Options({components: {Post, PostGrid, Upload, CloudSearchOutline, Undo}})
export default class CreateChannel extends Vue {
    private channelHttpService: ChannelHttpService = new ChannelHttpService();

    private channel: ChannelEntity = new ChannelEntity();
    private editMode: Boolean = false;
    private formLoading: Boolean = false;
    private previewLoading: Boolean = false;
    private previewPosts: Array<UserPostEntity> = [];

    private emptyPreviewPlaceholder = "";

    private formRef = ref<FormInstance>();
    private formValidationRules = reactive<FormRules>({
        name: [
            {required: true, message: 'Please input channel name', trigger: 'blur'}
        ],
        tagName: [
            {required: true, message: 'Please input channel tag', trigger: 'blur'}
        ],
        selector: [
            {required: true, message: 'Please input channel selector', trigger: 'blur'}
        ],
        url: [
            {required: true, message: 'Please input channel url'},
            {validator: this.urlValidator, trigger: 'blur'}
        ]
    })

    //TODO implement options when it'll be possible to index youtube channels
    private options: Array<Option> = [];

    public mounted(): void {
        if (this.$route.params.id) {
            this.editMode = true;
            this.loadPost(Number.parseInt(this.$route.params.id.toString()))
                .then(() => this.loadPreview(this.formRef))
        }
    }

    public loadPost(id: Number): Promise<any> {
        this.formLoading = true;
        return this.channelHttpService.loadChannel(id)
            .then(channel => this.channel = channel)
            .finally(() => this.formLoading = false);
    }

    public loadPreview(formInstance: FormInstance | undefined | any /*ts bug, can's pass ref<Value> instead of value*/): void {
        this.validateForm(formInstance)
            .then((valid) => {
                if (!valid) {
                    return;
                }
                this.previewLoading = true;
                this.channelHttpService.loadPreview(this.channel)
                    .then((preview) => {
                        //TODO
                        this.previewPosts = Array.from(preview, post => {
                            post.tags = Array.of({
                                id: '0',
                                name: this.channel.tagName
                            });
                            return {
                                post: post,
                                postState: {
                                    liked: false,
                                    bookmarked: false
                                }
                            }
                        })

                        if (!this.previewPosts || this.previewPosts.length == 0) {
                            this.emptyPreviewPlaceholder = "No post found with specified url and selector. Please try one more time";
                        }
                    })
                    .catch(() => this.emptyPreviewPlaceholder = "Error during preview loading. Please try other selector.")
                    .finally(() => this.previewLoading = false);
            });

    }

    public onEdit(formInstance: FormInstance | undefined): void {
        this.validateForm(formInstance)
            .then((valid) => {
                if (!valid) {
                    return
                }
                this.channelHttpService.updateChannel(this.channel)
                    .then(() => ElMessage({
                        message: `Channel '${this.channel.name}' was edited successfully`,
                        type: "success",
                        showClose: true
                    }))
                    .catch(() => ElMessage({
                        message: `An error occurred during the channel update`,
                        type: "error",
                        showClose: true
                    }))
                    .finally(() => this.navigateToChannels());

            })
    }

    public onSave(formInstance: FormInstance | undefined): void {
        this.validateForm(formInstance)
            .then((valid) => {
                if (!valid) {
                    return;
                }

                this.channelHttpService.createChannel(this.channel)
                    .then(() => ElMessage({
                        message: `Channel '${this.channel.name}' was created`,
                        type: "success",
                        showClose: true
                    }))
                    .catch(() => ElMessage({
                        message: `An error occurred during the channel creation`,
                        type: "error",
                        showClose: true
                    }))
                    .finally(() => this.navigateToChannels());
            })
    }

    public validateForm(formInstance: FormInstance | undefined): Promise<Boolean> {
        if (!formInstance) return Promise.resolve(false);

        return formInstance.validate((valid) => {
            return valid
        });
    }

    public navigateToChannels(): void {
        this.$router.replace({path: '/channels'});
    }

    public urlValidator(rule: any, value: any, callback: any) {
        if (!value) {
            return callback(new Error('Please input channel url'));
        }

        if (!ValidatorUtils.isUrl(value)) {
            return callback(new Error('Url is not valid. Please enter valid url'));
        }

        return callback();
    }
}
