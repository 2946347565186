import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-965e08a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control-panel-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heart = _resolveComponent("heart")!
  const _component_heart_outline = _resolveComponent("heart-outline")!
  const _component_trending_up = _resolveComponent("trending-up")!
  const _component_square_edit_outline = _resolveComponent("square-edit-outline")!
  const _component_playlist_plus = _resolveComponent("playlist-plus")!
  const _component_new_box = _resolveComponent("new-box")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_octagram = _resolveComponent("octagram")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_bookmark = _resolveComponent("bookmark")!
  const _component_bookmark_outline = _resolveComponent("bookmark-outline")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass([{ active: this.isLiked() }, "like"]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.likePost && _ctx.likePost(...args)))
    }, [
      (_ctx.isLiked())
        ? (_openBlock(), _createBlock(_component_heart, {
            key: 0,
            size: 23,
            title: "Unlike"
          }))
        : _createCommentVNode("", true),
      (!_ctx.isLiked())
        ? (_openBlock(), _createBlock(_component_heart_outline, {
            key: 1,
            size: 23,
            title: "Like"
          }))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasRole('ROLE_EDITOR'))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass([{ active: this.isRecommended() }, "recommend"]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addToRecommendations && _ctx.addToRecommendations(...args)))
        }, [
          _createVNode(_component_trending_up, { title: "Recommend" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.hasRole('ROLE_ADMIN'))
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$router.push({path: `/post/${this.userPost.post.id}`})))
        }, [
          _createVNode(_component_square_edit_outline, { title: "Edit post" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasRole('ROLE_EDITOR'))
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.loadNewsLists && _ctx.loadNewsLists(...args)))
        }, [
          _createVNode(_component_el_dropdown, {
            "hide-on-click": true,
            "max-height": "150",
            trigger: "click"
          }, {
            dropdown: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_dropdown_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        style: {"font-weight":"bold","margin-right":"10px"},
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (this.$router.push({path: '/list'})))
                      }, " Create new bookmark list "),
                      _createVNode(_component_new_box, { title: "create new news-list" })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userPostNewsListsState, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: item.list.name,
                      divided: index === 0,
                      onClick: ($event: any) => (item.in ? _ctx.deletePostFromNewsList(item.list) : _ctx.addPostToNewsList(item.list))
                    }, {
                      default: _withCtx(() => [
                        (item.in)
                          ? (_openBlock(), _createBlock(_component_octagram, {
                              key: 0,
                              size: 18,
                              style: {"margin-right":"5px"},
                              title: "post is readlist"
                            }))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(item.list.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["divided", "onClick"]))
                  }), 128))
                ]),
                _: 1
              })), [
                [_directive_loading, _ctx.listLoading]
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_playlist_plus, { title: "Add to bookmark list" })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: _normalizeClass([{ active: this.isBookmarked() }, "bookmark"]),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.changeBookmarkState && _ctx.changeBookmarkState(...args)))
    }, [
      (_ctx.isBookmarked())
        ? (_openBlock(), _createBlock(_component_bookmark, {
            key: 0,
            size: 23,
            title: "Remove Bookmark"
          }))
        : _createCommentVNode("", true),
      (!_ctx.isBookmarked())
        ? (_openBlock(), _createBlock(_component_bookmark_outline, {
            key: 1,
            size: 23,
            title: "Add Bookmark"
          }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}