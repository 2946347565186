import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { role: "main" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagFilter = _resolveComponent("TagFilter")!
  const _component_PopularPostBoard = _resolveComponent("PopularPostBoard")!
  const _component_RecommendedPostBoard = _resolveComponent("RecommendedPostBoard")!
  const _component_CompactPostBoard = _resolveComponent("CompactPostBoard")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TagFilter, { onTagUpdated: _ctx.onTagsUpdated }, null, 8, ["onTagUpdated"]),
      _createVNode(_component_PopularPostBoard, { requestedOptions: _ctx.searchParams }, null, 8, ["requestedOptions"]),
      _createVNode(_component_RecommendedPostBoard, { requestedOptions: _ctx.searchParams }, null, 8, ["requestedOptions"]),
      _createVNode(_component_CompactPostBoard, { requestedOptions: _ctx.searchParams }, null, 8, ["requestedOptions"])
    ])
  ]))
}