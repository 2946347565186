
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import Channel from "@/components/channel/Channel.vue";
import {ChannelEntity} from "@/model/entities/ChannelEntity";

@Options({components: {Channel}})
export default class ChannelGrid extends Vue {
    @Prop({type: Object as PropType<Array<ChannelEntity>>})
    private channels: Array<ChannelEntity> = [];

}
