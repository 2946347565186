import {reactive, toRefs} from 'vue'
import {server} from "@/server/http-common";

export interface User {
    id?: string;
    username: String;
    picture: String;
    roles: Array<String>;

    //hasRole?(role: String): boolean;
}

export interface AuthState {
    authenticated: boolean;
    user?: User;
    initialized: boolean;
    //hasRole?(role: String): boolean;
}

const state = reactive<AuthState>({
    authenticated: false,
    user: undefined,
    initialized: false,
    //hasRole: (role: String) => state?.user?.roles.includes(role)
})

export const useAuth = () => {

    const setUser = (user: User) => {
        state.user = user
        state.authenticated = state.user != undefined;
        state.initialized = true;
    }

    const getUser = async () => {
        //console.log('auth.ts is init: ' + state.initialized)
        if(state.initialized || !await isAuthenticated()) {
            return state.user
        }

        //console.log('auth.ts getUserDetails')
        const user: User = await getUserDetails()
        setUser(user)
        //console.log('auth.ts getUserDetails loaded')
        return user;
    }

    const isAuthenticated = async (): Promise<boolean> => {
        return await server.get("/api/v1/users/authenticated", {withCredentials: true})
            .then(response => {
                //console.log('auth.ts isAuthenticated')
                return response.data;
            });
    }

    const getUserDetails = async (): Promise<User> => {
        const response = await server.get("/api/v1/users/details", {withCredentials: true});
        return {
            username: response.data.username,
            roles: response.data.roles,
            picture: response.data.picture
        } as User
    }

    const logout = (): Promise<void> => {
        return Promise.resolve(state.user = undefined)
    }

    const hasRole = (role: String): boolean => {
        return isAdmin() || Boolean(state?.user?.roles.includes(role));
    }

    const isAdmin = (): boolean => {
        return Boolean(state?.user?.roles.includes('ROLE_ADMIN'));
    }

    return {
        setUser,
        getUser,
        isAuthenticated,
        logout,
        hasRole,
        ...toRefs(state),
    }
}