

import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {ElDialog, ElForm, ElMessage} from "element-plus";
import TagsBoard from "@/components/commons/TagsBoard.vue"
import ApprovedPostTable from "@/components/post/ApprovedPostTable.vue";
import EmptyValueInput from "@/components/commons/EmptyValueInput.vue";
import {EmailModuleDescription} from "@/model/entities/EmailModuleDescription";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {TextScanner} from "@/utils/TextScanner";

@Options({components: {ElForm, TagsBoard, PostTable: ApprovedPostTable, ElDialog, EmptyValueInput}, emits: ["module-update", "delete-module-item"]})
export default class EmailModuleItem extends Vue {

    private textScanner = new TextScanner();

    private simpleVariableMapperFunction = (s: String) => s.substring(2, s.length - 2).trim();
    private simpleVariablePattern = new RegExp("{{[ ]*([A-Za-z]*)[ ]*}}", "g");

    @Prop({type: Object as PropType<EmailModuleDescription>})
    private moduleDescription!: EmailModuleDescription;

    @Prop({type: Number as PropType<Number>})
    private index!: Number;

    @Prop({type: Boolean as PropType<Boolean>})
    private showDeleteButton: Boolean = false;

    private moduleDialogVisible: Boolean = false;
    private dependencyDialog!: String;

    @Emit("module-update")
    private emitUpdated() {
        return this.moduleDescription;
    }

    @Emit("delete-module-item")
    private onModuleDelete() {
        return this.index;
    }

    private openModuleDialog(dependencyDialog: String) {
        if(this.moduleDescription.dependencyParams.get("post")?.length == 10) {
            ElMessage({message: 'The maximum number of \'' + dependencyDialog + '\' parameters has been reached', type: "warning"})
            return
        }
        this.dependencyDialog = dependencyDialog;
        this.moduleDialogVisible = true;
    }

    private moveCardUp(dependencyId: Number) {
      this.moveCard(dependencyId, -1)
    }

    private moveCardDown(dependencyId: Number) {
      this.moveCard(dependencyId, 1)
    }

    private moveCard(dependencyId: Number, step: number) {
      let dependencyIds: Number[] = this.moduleDescription.dependencyParams.get("post")!;

      if(!dependencyIds) {
        return
      }

      const currentIndex = dependencyIds.indexOf(dependencyId)
      if((step > 0 && currentIndex == dependencyIds.length - 1) ||
          step < 0 && currentIndex == 0) {
        return
      }

      const item = dependencyIds[currentIndex]
      dependencyIds.splice(currentIndex, 1)
      dependencyIds.splice(currentIndex + step, 0, item)
      this.emitUpdated()
    }

    private onSelectedPosts(userPost: UserPostEntity) {
        if(!this.moduleDescription.dependencyParams.has("post")) {
            this.moduleDescription.dependencyParams.set("post", new Array<Number>());
        }

        let postIds: Number[] = this.moduleDescription.dependencyParams.get("post")!;
        if(postIds.includes(userPost.post.id)) {
          return
        }

        postIds.push(userPost.post.id);
        this.moduleDialogVisible = false;
        this.emitUpdated()
    }

    private onPostDelete(dependencyId: Number) {
        let postIds: Number[] = this.moduleDescription.dependencyParams.get("post")!;
        this.moduleDescription.dependencyParams.set("post", postIds.filter(item => item !== dependencyId));

        let params: Map<String, String> = this.moduleDescription.params;
        params.forEach((value: String, key: String) => {
            if(key.startsWith(dependencyId + '.')) {
                params.delete(key);
            }
        });
        this.emitUpdated();
    }

    private onFieldUpdate(dependencyId: Number, alias: String, value: String) {
        this.moduleDescription.params.set(dependencyId + '.' + alias, value);
        this.emitUpdated();
    }

}
