import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76f72b34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_UserEmailTable = _resolveComponent("UserEmailTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_EmailTemplateTable = _resolveComponent("EmailTemplateTable")!
  const _component_EmailModuleTable = _resolveComponent("EmailModuleTable")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      class: "tabs",
      onTabClick: _ctx.onTabChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          set: _ctx.emailIndex = 'emails',
          label: _ctx.tabs.get(_ctx.emailIndex),
          name: _ctx.emailIndex
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { style: {"margin-bottom":"8px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  plain: "",
                  onClick: _ctx.redirectToEmailPage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Create emails")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_UserEmailTable, { manageable: true })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["set", "label", "name"]),
        _createVNode(_component_el_tab_pane, {
          set: _ctx.templateIndex = 'templates',
          label: _ctx.tabs.get(_ctx.templateIndex),
          name: _ctx.templateIndex
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { style: {"margin-bottom":"8px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  plain: "",
                  onClick: _ctx.redirectToTemplatePage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Create template")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_EmailTemplateTable, {
                  manageable: true,
                  "single-select": false,
                  "multiple-select": false
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["set", "label", "name"]),
        _createVNode(_component_el_tab_pane, {
          set: _ctx.moduleIndex = 'modules',
          label: _ctx.tabs.get(_ctx.moduleIndex),
          name: _ctx.moduleIndex
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { style: {"margin-bottom":"8px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  plain: "",
                  onClick: _ctx.redirectToModulePage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Create module")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_EmailModuleTable, {
                  modules: _ctx.modules,
                  manageable: true,
                  selectable: false
                }, null, 8, ["modules"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["set", "label", "name"])
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}