import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    placeholder: _ctx.placeholder,
    autosize: {minRows: 1},
    size: "small",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    type: "textarea",
    onChange: _ctx.emitInput,
    onInput: _ctx.emitInput
  }, null, 8, ["placeholder", "modelValue", "onChange", "onInput"]))
}