import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_table, {
      onCurrentChange: _ctx.handleCurrentChange,
      data: _ctx.templates,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        (_ctx.multipleSelect)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              type: "selection",
              width: "55"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_table_column, {
          label: "Name",
          property: "name"
        }),
        (_ctx.manageable)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              label: "Operations",
              align: "right"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  size: "small",
                  onClick: ($event: any) => (_ctx.onEdit(scope.$index, scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Edit")
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_el_button, {
                  size: "small",
                  type: "danger",
                  onClick: ($event: any) => (_ctx.onDelete(scope.$index, scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Delete")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onCurrentChange", "data"]),
    _createVNode(_component_el_dialog, {
      title: "Confirmation",
      width: "20%",
      modelValue: _ctx.confirmDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmDialogVisible) = $event))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            onClick: _ctx.closeModuleDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode("Okay")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createTextVNode(" '" + _toDisplayString(_ctx.selectedTemplate.name) + "' email was sent successfully ", 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}