import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    "show-header": false,
    data: _ctx.fields,
    "row-class-name": _ctx.tableRowClassName
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, null, {
        default: _withCtx((scope) => [
          _createElementVNode("div", null, _toDisplayString(scope.row.option.alias), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, null, {
        default: _withCtx((scope) => [
          _createElementVNode("div", null, _toDisplayString(scope.row.option.description), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "row-class-name"]))
}