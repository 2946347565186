import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dd1f30c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-template" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_EmailModuleItem = _resolveComponent("EmailModuleItem")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_EmailModuleTable = _resolveComponent("EmailModuleTable")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_breadcrumb, { style: {"margin-bottom":"15px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb_item, { to: { name: 'EmailBuilder', query: {active: 'templates'} } }, {
          default: _withCtx(() => [
            _createTextVNode(" templates ")
          ]),
          _: 1
        }),
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createTextVNode("create template")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_form, {
      model: _ctx.template,
      "label-width": "auto",
      ref: "ruleFormRef",
      rules: _ctx.defaultRules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { style: {"margin-bottom":"8px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveTemplate(_ctx.ruleFormRef)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$route.params.id ? "Create new" : "Save template"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Name",
          prop: "name",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.template.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.template.name) = $event)),
              class: "m-2",
              "show-word-limit": "",
              maxlength: "50"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_VueDraggableNext, {
            list: _ctx.template.modules,
            tag: "transition-group",
            onChange: _ctx.updateModulePositions
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.template.modules, (description, index) => {
                return (_openBlock(), _createElementBlock("div", { key: description }, [
                  _createVNode(_component_EmailModuleItem, {
                    "module-description": description,
                    index: index,
                    showDeleteButton: true,
                    onModuleUpdate: _ctx.updatePreview,
                    onDeleteModuleItem: _ctx.removeModule
                  }, null, 8, ["module-description", "index", "onModuleUpdate", "onDeleteModuleItem"])
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["list", "onChange"]),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                plain: "",
                onClick: _ctx.openModuleDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add modules ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_dialog, {
      title: "Choose email module",
      width: "600px",
      modelValue: _ctx.moduleDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.moduleDialogVisible) = $event)),
      onOpen: _ctx.loadEmailModules
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            onClick: _ctx.addModules
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _ctx.closeModuleDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_EmailModuleTable, {
              modules: _ctx.allModules,
              manageable: false,
              selectable: true,
              onOnModuleSelection: _ctx.onModuleSelection
            }, null, 8, ["modules", "onOnModuleSelection"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onOpen"])
  ], 64))
}