import {EmailTemplateEntity} from "@/model/entities/EmailTemplateEntity";
import {EmailModuleDescriptionMapper} from "@/model/mapper/EmailModuleDescriptionMapper";

export default class EmailTemplateMapper {
    static toRegularObject(emailTemplateEntity: EmailTemplateEntity): EmailTemplateEntity {
        return {
            id: emailTemplateEntity.id,
            name: emailTemplateEntity.name,
            email: emailTemplateEntity.email,
            modules: Array.from(emailTemplateEntity.modules, (module) => {
                return EmailModuleDescriptionMapper.toRegularObject(module)
            })
        }
    }

    static fromRegularObject(objectTemplate: any) {
        const emailTemplate = new EmailTemplateEntity();
        emailTemplate.id = objectTemplate.id;
        emailTemplate.email = objectTemplate.email;
        emailTemplate.name = objectTemplate.name;
        emailTemplate.modules = Array.from(objectTemplate.modules, module => EmailModuleDescriptionMapper.formRegularObject(module));
        // @ts-ignore
        emailTemplate.modules.sort((first, second) => first.position - second.position)

        return emailTemplate;
    }
}