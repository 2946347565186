import {Tag} from "@/model/entities/Tag";

export class TagSet {
    id!: Number;
    name!: String;
    tags: Array<Tag> = [];

    public constructor(id?: Number, name?: String, tags?: Array<Tag>) {
        if(id) {
            this.id = id;
        }

        if(name) {
            this.name = name
        }

        if(tags) {
            this.tags = tags;
        }
    }

    public contains(tag: String): Boolean {
        if (!this.tags) {
            return false;
        }
        return new Set(Array.from(this.tags, tag => tag.name)).has(tag);
    }
}