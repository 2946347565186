import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewGridOutline = _resolveComponent("ViewGridOutline")!
  const _component_ViewAgendaOutline = _resolveComponent("ViewAgendaOutline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ViewGridOutline, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLayout('Card'))),
      style: _normalizeStyle(_ctx.layout === 'Card' ? 'color: var(--el-color-primary)' : 'color: var(--el-color-info)'),
      size: 23,
      title: "Grid View"
    }, null, 8, ["style"]),
    _createVNode(_component_ViewAgendaOutline, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLayout('Table'))),
      style: _normalizeStyle(_ctx.layout === 'Table' ? 'color: var(--el-color-primary)' : 'color: var(--el-color-info)'),
      size: 23,
      title: "List View"
    }, null, 8, ["style"])
  ], 64))
}