import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "non-selectable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_open_in_new = _resolveComponent("open-in-new")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.tableData,
    "highlight-current-row": "",
    onCurrentChange: _ctx.onSelection
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        property: "title",
        label: "Title"
      }, {
        default: _withCtx((scope) => [
          (_ctx.enableResourceLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: scope.row.post.url
              }, _toDisplayString(scope.row.post.title), 9, _hoisted_1))
            : _createCommentVNode("", true),
          (!_ctx.enableResourceLink)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row.post.title), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_open_in_new, {
            onClick: ($event: any) => (_ctx.openPost(scope.row)),
            size: 23,
            style: _normalizeStyle('color: var(--el-color-info); margin-left: 5px')
          }, null, 8, ["onClick", "style"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "onCurrentChange"]))
}