
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import {server} from "@/server/http-common";
import {ElMessage} from "element-plus";
import MotionPlay from 'vue-material-design-icons/MotionPlay.vue';
import PlayOutline from 'vue-material-design-icons/PlayOutline.vue'
import TimerPlayOutline from 'vue-material-design-icons/TimerPlayOutline.vue'
import TimerStopOutline from 'vue-material-design-icons/TimerStopOutline.vue'
import PencilOutline from 'vue-material-design-icons/PencilOutline.vue'

@Options({components: {MotionPlay, PlayOutline, TimerPlayOutline, TimerStopOutline, PencilOutline}})
export default class ChannelControlPanel extends Vue {
    @Prop({type: Object as PropType<ChannelEntity>})
    private channel!: ChannelEntity;

    @Prop({type: Boolean as PropType<Boolean>})
    private forceCollapse!: Boolean;

    private isIndexing: Boolean = false;
    private collapsed = this.changeMenuLayout();

    public mounted(): void {
        if (this.forceCollapse === null) {
            window.addEventListener('resize', this.changeMenuLayout);
            return;
        }
        this.collapsed = this.forceCollapse;
    }

    private changeMenuLayout(): Boolean {
        this.collapsed = (window.innerWidth < 790);
        return this.collapsed;
    }

    public indexCurrent(): void {
        this.isIndexing = true;
        server.post(`/api/v1/channels/${this.channel.id}/index`)
            .then(() => {
                this.isIndexing = false;
            })
    }

    get isActive(): Boolean {
        return this.channel.status == "ACTIVE"
    }

    private activateChannel(): void {
        if (this.isActive) {
            return
        }

        server.put(`/api/v1/channels/${this.channel.id}/activate`)
            .then(() => {
                this.channel.status = "ACTIVE";
                ElMessage({message: `Channel '${this.channel.name}' is activated`, type: "success"})
            })
            .catch(error => {
                console.error(error);
                ElMessage({message: error.response.data.error, type: "error"})
            })
    }

    private deactivateChannel(): void {
        if (!this.isActive) {
            return
        }

        server.put(`/api/v1/channels/${this.channel.id}/deactivate`)
            .then(() => {
                this.channel.status = "INACTIVE";
                ElMessage({message: `Channel '${this.channel.name}' is deactivated`, type: "success"})
            })
            .catch(error => {
                console.error(error);
                ElMessage({message: error.response.data.error, type: "error"})
            })
    }

    private toEditMode() {
        if(this.channel.id) {
            this.$router.push({name: 'Channel', params: {id: this.channel.id.toString()}})
        }
    }
}
