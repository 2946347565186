import {UserEmailHttpService} from "@/server/services/UserEmailHttpService";
import {UserEmailEntity} from "@/model/entities/UserEmailEntity";
import {server} from "@/server/http-common";
import {UserEmailMapper} from "@/model/mapper/UserEmailMapper";

export class DefaultUserEmailHttpService implements UserEmailHttpService {
    private userEmailMapper: UserEmailMapper = new UserEmailMapper();
    private server = server;
    private defaultApiPrefix: String = "/api/v1/user/emails";

    public getAllUserEmails(): Promise<UserEmailEntity[]> {
        return this.server.get(this.defaultApiPrefix.toString())
            .then(response => {
                return Array.from(response.data, email => this.userEmailMapper.fromRegularObject(email))
            })
    }

    public getUserEmail(id: Number): Promise<UserEmailEntity> {
        return this.server.get(`${this.defaultApiPrefix}/${id}`)
            .then(response => {
                return this.userEmailMapper.fromRegularObject(response.data);
            });
    }

    public createUserEmail(userEmail: UserEmailEntity): Promise<any> {
        return this.server.post(this.defaultApiPrefix.toString(), this.userEmailMapper.toRegularObject(userEmail))
    }

    public deleteUserEmail(id: Number): Promise<any> {
        return this.server.delete(`${this.defaultApiPrefix}/${id}`);
    }

    public updateUserEmail(userEmail: UserEmailEntity): Promise<UserEmailEntity> {
        return this.server.put(`${this.defaultApiPrefix}`, this.userEmailMapper.toRegularObject(userEmail))
            .then((response) => {
                return response.data
            })
    }

    public sendUserEmail(userEmail: UserEmailEntity): Promise<any> {
        return this.server.post(`${this.defaultApiPrefix}/send`, this.userEmailMapper.toRegularObject(userEmail));
    }


}