export class TextScanner {
    public getAllMatches(text: String, pattern: RegExp): Array<String> {
        return  Array.from(text.matchAll(pattern), m => m[0]);
    }

    public getUniqueMatches(text: String,
                            pattern: RegExp,
                            mapper: (value: String, index: Number, array: Array<String>) => String = (s) => s
    ): Array<String> {
        return Array.from(new Set(this.getAllMatches(text, pattern))).map(mapper);
    }
}
