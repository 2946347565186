import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8a6cbbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-top":"15px"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","height":"16px"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title-wrapper" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "tags-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_PostControlPanel = _resolveComponent("PostControlPanel")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "post-card",
    shadow: "never",
    "body-style": { padding: '10px' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_skeleton, {
        loading: !this.userPost,
        animated: ""
      }, {
        template: _withCtx(() => [
          _createVNode(_component_el_skeleton_item, {
            variant: "image",
            style: {"width":"100%","height":"160px"}
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_skeleton_item, { variant: "h3" }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_skeleton_item, { variant: "text" })
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: (_ctx.userPost.post.previewImageUrl) ? _ctx.userPost.post.previewImageUrl : _ctx.userPost.post.defaultImage,
            class: "image",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPost && _ctx.openPost(...args))),
            onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args)))
          }, null, 40, _hoisted_3),
          (_ctx.userAuthenticated)
            ? (_openBlock(), _createBlock(_component_PostControlPanel, {
                key: 0,
                "user-post": _ctx.userPost
              }, null, 8, ["user-post"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_link, {
              underline: false,
              onClick: _ctx.openPost,
              title: _ctx.userPost.post.title
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.userPost.post.title), 1)
              ]),
              _: 1
            }, 8, ["onClick", "title"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userPost.post.tags, (tag) => {
              return (_openBlock(), _createBlock(_component_el_tag, {
                key: tag.id,
                class: "non-selectable post-tag",
                type: "info",
                effect: "plain",
                onClick: ($event: any) => (_ctx.tagSelected(tag.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tag.name), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128)),
            _createVNode(_component_el_tag, {
              effect: "plain",
              style: {"margin-bottom":"3px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userPost.post.creationTimeAgo), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }))
}