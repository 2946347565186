
import {Options, Vue} from "vue-class-component";
import {InjectReactive, Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import PostControlPanel from "@/components/post/PostControlPanel.vue";
import {StatisticsHttpService} from "@/server/services/impl/StatisticsHttpService";
import {ElTag} from "element-plus";

@Options({components: {ElTag, PostControlPanel}})
export default class DefaultPostTable extends Vue {
    private statisticService: StatisticsHttpService = new StatisticsHttpService();

    @Prop({type: Object as PropType<Array<UserPostEntity>>})
    private posts: Array<UserPostEntity> = [];

    @InjectReactive('authenticated')
    private userAuthenticated!: Boolean;

    public openPost(post: UserPostEntity): void {
        window.open(post.post.url.toString());
        this.statisticService.saveVisit(post.post.id)
    }
}
