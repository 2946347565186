

import {server} from "@/server/http-common";
import {ElButton, ElCard, ElMessage, ElTag} from "element-plus";
import {Options, Vue} from "vue-class-component";
import {InjectReactive, Prop} from "vue-property-decorator";
import {PropType} from "vue";
import PostControlPanel from "@/components/post/PostControlPanel.vue";
import {postEmitter, TAG_SELECTED} from "@/events/PostEventBus";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {StatisticsHttpService} from "@/server/services/impl/StatisticsHttpService";

@Options({components: {PostControlPanel, ElButton, ElCard, ElTag, postEmitter}})
export default class Post extends Vue {
    private statisticsService: StatisticsHttpService = new StatisticsHttpService();

    @Prop({type: Object as PropType<UserPostEntity>})
    private userPost!: UserPostEntity;

    @InjectReactive('authenticated')
    private userAuthenticated!: Boolean;

    private proxyImg: Boolean = false;

    private onError(e): void {
        if (!this.proxyImg) {
            e.target.src = '/api/v1/posts/'+ this.userPost.post.id + '/thumbnail';
            this.proxyImg = true
        }
    }

    private tagSelected(tag) {
        postEmitter.emit(TAG_SELECTED, tag)
    }

    remove() {
        server.delete("/api/v1/posts/" + this.userPost.post.id)
            .then((response) => {
                if (response.data.success) {
                    this.$emit('delete-post');
                    ElMessage({message: 'Post approved', type: "success"})
                }
            })
            .catch(err => {
                ElMessage({message: err.response.data.error, type: "error"})
            })
    }

    public openPost(post: UserPostEntity): void {
        console.log(post);
        window.open(this.userPost.post.url.toString());
        this.statisticsService.saveVisit(this.userPost.post.id)
    }

}
