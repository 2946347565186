

import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";

export default class DeleteDialog extends Vue {
    @Prop({type: Boolean as PropType<Boolean>})
    private visible!: boolean;

    @Prop({type: Function as PropType<Function>})
    private onCancelCallback: Function = () => {}

    @Prop({type: Function as PropType<Function>})
    private onDeleteCallback: Function = () => {}

    @Prop({type: String})
    private title!: String;

    @Prop({type: String})
    private content!: String;
}

