<template>
    <div class="container">

        <VisitsLineChart/>

    </div>
</template>

<script lang="js">

import VisitsLineChart from "@/components/VisitsLineChart.vue";
import {server} from "@/server/http-common.ts";

export default {
    name: "Statistics",
    components: {VisitsLineChart},
    data() {
        return {
            statsData: [],
            totalItemsCount: 0
        }
    },
    methods: {
        fetchStats(sortField, pageNumber, pageSize) {
            server.get("/api/v1/statistics/channels", {
                withCredentials: true,
                params: {daysCount: 7, pageSize: pageSize, page: pageNumber, sortField: sortField}
            }).then(response => {
                this.totalItemsCount = response.data.totalItemsCount;
                this.statsData = response.data.content
            });
        }
    }
}
</script>

<style scoped>

.return-link {
    display: flex;
    text-decoration: none;
    color: #6f787f
}

.return-link:hover {
    display: flex;
    text-decoration: none;
    color: #8c99a0
}

</style>