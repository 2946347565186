import {server} from "@/server/http-common";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import {PostEntity} from "@/model/entities/PostEntity";

export class ChannelHttpService {
    private server = server;
    private defaultApiPrefix: String = "/api/v1/channels";

    public loadChannel(id: Number): Promise<ChannelEntity> {
        return this.server.get(`${this.defaultApiPrefix}/${id}`).then(response => {
            return response.data
        });
    }

    public loadPreview(channel: ChannelEntity): Promise<Array<PostEntity>> {
        return this.server.get(`${this.defaultApiPrefix}/preview/`, {params: channel}).then(response => {
            return response.data
        })
    }

    public updateChannel(channel: ChannelEntity): Promise<any> {
        return this.server.put(`${this.defaultApiPrefix}`, channel);
    }

    public createChannel(channel: ChannelEntity): Promise<any> {
        //TODO (vue-axios bug) move to request body when bug will be fixed
        const params = new URLSearchParams();
        for (const key in channel) {
            const value = channel[key];
            if (value) {
                params.append(key.toString(), value.toString());
            }
        }
        return this.server.post("/api/v1/channels", params);
    }

    public getChannelsByQuery(name: String): Promise<Array<ChannelEntity>> {
        return this.server.get(`${this.defaultApiPrefix}/like`, {params: {queryString: name}})
            .then((response) => {
                return response.data
            });
    }
}