
import {Options, Vue} from "vue-class-component";
import {UserNewsListsHttpService} from "@/server/services/UserNewsListsHttpService";
import {DefaultNewsListsHttpService} from "@/server/services/impl/DefaultNewsListsHttpService";
import {ElMessage} from "element-plus";

@Options({})
export default class EditList extends Vue {
    private newsListService: UserNewsListsHttpService = new DefaultNewsListsHttpService();

    private loading: Boolean = false;
    private listName = "";

    public onCreate() {
        this.newsListService.createNewsList(this.listName)
            .then(() => ElMessage({message: 'New List successfully created', type: "success"}))
            .then(() => this.$router.push({ path: '/dashboard'}));
    }

}
