import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between","flex-wrap":"wrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ChannelControlPanel = _resolveComponent("ChannelControlPanel")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.channels,
    "default-sort": { prop: 'name', order: 'asc' },
    "empty-text": "No channels found for this input."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "name",
        label: "Name",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_link, {
              underline: false,
              onClick: ($event: any) => (this.$router.push({name: 'Channel', params: {id: scope.row.id}}))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.name), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_tag, {
              size: "small",
              type: "info"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.tagName), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { width: 270 }, {
        default: _withCtx((scope) => [
          _createVNode(_component_ChannelControlPanel, {
            "force-collapse": true,
            channel: scope.row
          }, null, 8, ["channel"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}