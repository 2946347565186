
import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import {PropType} from "vue/dist/vue";
import {server} from "@/server/http-common";
import {ElMessage} from "element-plus";
import Heart from 'vue-material-design-icons/Heart.vue';
import HeartOutline from 'vue-material-design-icons/HeartOutline.vue';
import TrendingUp from 'vue-material-design-icons/TrendingUp.vue';
import Bookmark from 'vue-material-design-icons/Bookmark.vue';
import BookmarkOutline from 'vue-material-design-icons/BookmarkOutline.vue';
import SquareEditOutline from 'vue-material-design-icons/SquareEditOutline.vue';
import PlaylistPlus from 'vue-material-design-icons/PlaylistPlus.vue';
import NewBox from 'vue-material-design-icons/NewBox.vue';
import Octagram from 'vue-material-design-icons/Octagram.vue';
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {postEmitter, STATUS_UPDATED} from "@/events/PostEventBus";
import {useAuth} from "@/services/auth";
import {NewsList} from "@/model/entities/NewsList";
import {UserNewsListsHttpService} from "@/server/services/UserNewsListsHttpService";
import {DefaultNewsListsHttpService} from "@/server/services/impl/DefaultNewsListsHttpService";
import {NewsListPostState} from "@/model/entities/NewsListPostState";

@Options({
    components: {
        Heart,
        HeartOutline,
        TrendingUp,
        Bookmark,
        BookmarkOutline,
        SquareEditOutline,
        PlaylistPlus,
        NewBox,
        Octagram
    }
})
export default class PostControlPanel extends Vue {

    private newsListHttpService: UserNewsListsHttpService = new DefaultNewsListsHttpService();

    @Prop({type: Object as PropType<UserPostEntity>})
    private userPost!: UserPostEntity;

    private listLoading = true;

    //TODO
    private userPostNewsListsState: Array<NewsListPostState> = [];

    @Emit("state-changed")
    private stateChanged(): UserPostEntity {
        postEmitter.emit(STATUS_UPDATED, this.userPost)
        return this.userPost;
    }

    private likePost(): void {
        const isLiked: boolean = !this.userPost.postState.liked;
        server.put("/api/v1/posts/" + this.userPost.post.id + "/like", isLiked.toString(), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            this.userPost.postState.liked = isLiked;
        });
    }

    public changeBookmarkState(): void {
        const isBookmarked: boolean = !this.userPost.postState.bookmarked;
        server
            .put("/api/v1/posts/" + this.userPost.post.id + "/bookmark",
                isBookmarked.toString(),
                {withCredentials: true, headers: {'Content-Type': 'application/json'}})
            .then(() => {
                ElMessage({message: 'Post bookmarked', type: "success"})
                this.userPost.postState.bookmarked = isBookmarked;
            })
            .catch(error => {
                ElMessage({message: error.response.data.error, type: "error"});
            });
    }

    public addToRecommendations(): void {
        let newStatus = 'recommended';
        if (this.userPost.post.recommendationStatus === 'recommended') {
            newStatus = 'none'
        }

        server.put("/api/v1/posts/" + this.userPost.post.id + "/recommend", "\"" + newStatus + "\"", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            this.userPost.post.recommendationStatus = newStatus;

            newStatus === 'recommended'
                ? ElMessage({message: 'Post marked as recommended', type: "success"})
                : ElMessage({message: 'Post removed from recommended', type: "info"});

            this.stateChanged()
        }).catch(error => {
            ElMessage({message: error, type: "error"});
        });
    }

    private isRecommended(): boolean {
        return this.userPost.post.recommendationStatus === 'recommended';
    }

    private isBookmarked(): boolean {
        return this.userPost.postState.bookmarked;
    }

    private isLiked(): boolean {
        return this.userPost.postState.liked;
    }

    private hasRole(role: string): Boolean {
        return useAuth().hasRole(role)
    }

    private loadNewsLists() {
        this.listLoading = true;
        this.newsListHttpService.getCurrentUserNewsListsPostStatus(0, 100, this.userPost.post.id)
            .then(list => this.userPostNewsListsState = list)
            .finally(() => this.listLoading = false);
    }

    private addPostToNewsList(list: NewsList) {
        this.newsListHttpService.addPostToNewsList(list.id, this.userPost.post.id)
            .then(() => ElMessage({message: `Post added to readlist ${list.name}`, type: "success"}));
    }

    private deletePostFromNewsList(list: NewsList) {
        this.newsListHttpService.deletePostFromNewsList(list.id, this.userPost.post.id)
            .then(() => ElMessage({message: `Post removed from readlist ${list.name}`, type: "success"}));
    }
}
