import {EmailModuleDescription} from "@/model/entities/EmailModuleDescription";

export class EmailModuleDescriptionMapper {
    static toRegularObject(emailModuleDescription: EmailModuleDescription): EmailModuleDescription {
        return {
            position: emailModuleDescription.position,
            module: emailModuleDescription.module,
            params: Object.fromEntries(emailModuleDescription.params),
            dependencyParams: Object.fromEntries(emailModuleDescription.dependencyParams)
        };
    }

    static formRegularObject(emailModule: any) {
        const moduleDescription: EmailModuleDescription = new EmailModuleDescription();
        moduleDescription.module = emailModule.module;
        moduleDescription.position = emailModule.position;
        moduleDescription.params = new Map(Object.entries(emailModule.params));
        moduleDescription.dependencyParams = new Map(Object.entries((emailModule.dependencyParams)));
        return moduleDescription;
    }
}