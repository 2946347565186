import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagsBoard = _resolveComponent("TagsBoard")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_CategoryBoard = _resolveComponent("CategoryBoard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TagsBoard, {
      closable: true,
      addable: true,
      onTagRemoved: _ctx.onTagsUpdated,
      onTagAdded: _ctx.onTagsUpdated,
      "autocomplete-placeholder": "Search filter tag",
      "dynamic-tags": _ctx.activeTags,
      "suggestion-function": _ctx.querySearchTags
    }, null, 8, ["onTagRemoved", "onTagAdded", "dynamic-tags", "suggestion-function"]),
    _createVNode(_component_el_divider, { style: {"margin":"5px 0"} }),
    _createVNode(_component_CategoryBoard, {
      tags: _ctx.availableCategories,
      active: _ctx.activeCategories,
      onSelected: _ctx.onCategorySelect,
      onRemoved: _ctx.onCategoryRemove
    }, null, 8, ["tags", "active", "onSelected", "onRemoved"])
  ]))
}