import axios from "axios"

export const server = axios.create({
        //withCredentials: true,
        validateStatus: (status) => {
            return status >= 200 && status <= 304;
        }
    }
);

server.interceptors.response.use(
    (response) => response,
    (error) => { return Promise.reject(error); }
);