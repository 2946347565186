
import {Options, Vue} from "vue-class-component";
import EmailTemplateTable from "@/components/email/EmailTemplateTable.vue";
import EmailModuleTable from "@/components/email/EmailModuleTable.vue";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import {TabsPaneContext} from "element-plus";
import {EmailModuleHttpService} from "@/server/services/EmailModuleHttpService";
import {DefaultEmailModuleHttpService} from "@/server/services/impl/DefaultEmailModuleHttpService";
import UserEmailTable from "@/components/email/UserEmailTable.vue";

@Options({components: {UserEmailTable, EmailTemplateTable, EmailModuleTable}})
export default class EmailBuilder extends Vue {
    private emailModuleHttpService: EmailModuleHttpService = new DefaultEmailModuleHttpService();
    private activeTab: String = 'emails';
    private tabs = new Map<String, String>([["emails", "My Emails"], ["templates", "Templates"], ["modules", "Modules"]]);
    private modules: EmailModuleEntity[] = [];

    public mounted() {
        const activeTab = this.$route.query.active;
        if (activeTab && this.tabs.has(activeTab.toString())) {
            this.activeTab = activeTab.toString();
            //TODO
            this.emailModuleHttpService.loadModules().then(modules => this.modules = modules);
        }
    }

    private redirectToTemplatePage() {
        this.$router.push({path: '/emails/create-template'});
    }

    private redirectToModulePage() {
        this.$router.push({path: '/emails/create-module'});
    }

    private redirectToEmailPage() {
        this.$router.push({path: '/emails/create-email'});
    }

    private onTabChange(tab: TabsPaneContext): void {
        if (tab.paneName == 'modules') {
            this.emailModuleHttpService.loadModules().then(modules => this.modules = modules);
        } else {
            this.modules = [];
        }
    }
}
