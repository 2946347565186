import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tag-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.addable)
      ? (_openBlock(), _createBlock(_component_el_autocomplete, {
          key: 0,
          id: "autocomplete",
          placeholder: _ctx.autocompletePlaceholder,
          "value-key": "name",
          style: {"width":"10rem"},
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          size: "small",
          class: "tag",
          onKeyup: _withKeys(_ctx.onKeyupEnter, ["enter"]),
          onBlur: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          onSelect: _ctx.onInputConfirm,
          "fetch-suggestions": _ctx.suggestionFunction
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_magnify, { size: 19 })
          ]),
          _: 1
        }, 8, ["placeholder", "modelValue", "onKeyup", "onSelect", "fetch-suggestions"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicTags, (tag) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: tag,
        class: "tag non-selectable",
        closable: _ctx.closable,
        "disable-transitions": false,
        onClose: ($event: any) => (_ctx.removeTag(tag.name))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(tag.name), 1)
        ]),
        _: 2
      }, 1032, ["closable", "onClose"]))
    }), 128))
  ]))
}