import {server} from "@/server/http-common";

export class TagHttpService {

    private defaultApiPrefix = "/api/v1/tags";

    public getTagsNamesByQuery(query: string): Promise<any> {
        const options = new URLSearchParams({"queryString": query});
        return server.get(this.defaultApiPrefix, {params: options});
    }

    public getTagsWithNames(names: Array<String>) {
        const options = new URLSearchParams({"names": names.toString()});
        return server.get(this.defaultApiPrefix + "/tmp", {params: options});
    }

    public getTagByName(name: string): Promise<any> {
        return server.get(this.defaultApiPrefix + `/${name}`);
    }
}