import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap"} }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_PostControlPanel = _resolveComponent("PostControlPanel")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.posts,
    "empty-text": "No posts found. Perhaps you'd like to select a different tags?"
  }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            type: "selection",
            width: "30"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, null, {
        default: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_link, {
              underline: false,
              onClick: ($event: any) => (_ctx.openPost(scope.row))
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: (scope.row.post.previewImageUrl) ? scope.row.post.previewImageUrl : scope.row.post.defaultImage,
                  class: "image",
                  height: "40"
                }, null, 8, _hoisted_2),
                _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.post.title), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.post.tags.map(tag => tag.name), (tagName) => {
                return (_openBlock(), _createBlock(_component_el_tag, {
                  type: "info",
                  effect: "plain",
                  style: {"margin-right":"3px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tagName), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 256)),
              _createVNode(_component_el_tag, {
                effect: "plain",
                style: {"margin-right":"3px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.post.creationTimeAgo), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ]),
        _: 1
      }),
      (_ctx.userAuthenticated)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            width: 140
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_PostControlPanel, {
                "user-post": scope.row
              }, null, 8, ["user-post"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data"]))
}