

import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator"
import {PropType} from "vue";
import {ChannelEntity} from "@/model/entities/ChannelEntity";
import {server} from "@/server/http-common";
import {ElMessage} from "element-plus";
import ChannelControlPanel from "@/components/channel/ChannelControlPanel.vue";

@Options({components: {ChannelControlPanel}})
export default class Channel extends Vue {
    @Prop({type: Object as PropType<ChannelEntity>})
    private channel!: ChannelEntity;
    private deleteConfirmationDialogVisible: Boolean = false;

    get isActive(): Boolean {
        return this.channel.status == "ACTIVE"
    }

    private activateChannel(): void {
        if (this.isActive) {
            return
        }

        server.put(`/api/v1/channels/${this.channel.id}/activate`)
            .then(() => {
                this.channel.status = "ACTIVE";
                ElMessage({message: `Channel '${this.channel.name}' is activated`, type: "success"})
            })
            .catch(error => {
                console.error(error);
                ElMessage({message: error.response.data.error, type: "error"})
            })
    }

    private deactivateChannel(): void {
        if (!this.isActive) {
            return
        }

        server.put(`/api/v1/channels/${this.channel.id}/deactivate`)
            .then(() => {
                this.channel.status = "INACTIVE";
                ElMessage({message: `Channel '${this.channel.name}' is deactivated`, type: "success"})
            })
            .catch(error => {
                console.error(error);
                ElMessage({message: error.response.data.error, type: "error"})
            })
    }
}
