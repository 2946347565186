import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e603ead"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "pagination-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagFilter = _resolveComponent("TagFilter")!
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_PostGrid = _resolveComponent("PostGrid")!
  const _component_DefaultPostTable = _resolveComponent("DefaultPostTable")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TagFilter, { onTagUpdated: _ctx.tagsUpdated }, null, 8, ["onTagUpdated"]),
    _createVNode(_component_SectionTitle, {
      title: 'Advanced Search Panel',
      "enable-change-layout": true,
      "enable-period-select": true,
      "enable-sorting": true,
      layout: _ctx.layout,
      "default-active-sorting": _ctx.mask.sortBy,
      onSortingChanged: _ctx.sortingChanged,
      onLayoutChanged: _cache[0] || (_cache[0] = (layoutEvent) => this.layout = layoutEvent),
      onPeriodChanged: _ctx.periodChanged
    }, null, 8, ["layout", "default-active-sorting", "onSortingChanged", "onPeriodChanged"]),
    _createVNode(_component_el_input, {
      size: "medium",
      modelValue: _ctx.postSearchCriteria.pattern,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postSearchCriteria.pattern) = $event)),
      placeholder: "Start typing post title",
      onInput: _ctx.loadPosts
    }, null, 8, ["modelValue", "onInput"]),
    (_ctx.layout === 'Card')
      ? (_openBlock(), _createBlock(_component_PostGrid, {
          key: 0,
          posts: _ctx.postPage.content
        }, null, 8, ["posts"]))
      : _createCommentVNode("", true),
    (_ctx.layout === 'Table')
      ? (_openBlock(), _createBlock(_component_DefaultPostTable, {
          key: 1,
          posts: _ctx.postPage.content
        }, null, 8, ["posts"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.postSearchCriteria.page,
        "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.postSearchCriteria.page) = $event)),
        "page-size": _ctx.postSearchCriteria.size,
        "onUpdate:page-size": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postSearchCriteria.size) = $event)),
        "page-sizes": _ctx.pageSizes,
        size: "small",
        layout: "prev, pager, next, sizes",
        total: _ctx.postPage.totalItemsCount,
        onSizeChange: _ctx.loadPosts,
        onCurrentChange: _ctx.loadPosts,
        class: "flex-wrap"
      }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "onSizeChange", "onCurrentChange"])
    ])
  ]))
}