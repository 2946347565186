
import {Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {TagSet} from "@/model/entities/TagSet";

export default class CategoryBoard extends Vue {
    @Prop({type: Object as PropType<Array<TagSet>>})
    private tags: Array<TagSet> = new Array<TagSet>();

    @Prop({type: Object as PropType<Set<String>>})
    private active: Set<String> = new Set<String>();

    updateSelected(tag: TagSet) {
        if (this.active.has(tag.name)) {
            this.removeCategory(tag)
            return;
        }
        this.addCategory(tag)
    }

    @Emit("removed")
    removeCategory(tag) {
        this.active.delete(tag.name);
        return tag;
    }

    @Emit("selected")
    addCategory(tag) {
        this.active.add(tag.name);
        return tag;
    }

}
