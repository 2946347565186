import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17baa31e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "latest-post-container" }
const _hoisted_2 = { style: {"margin-top":"10px"} }
const _hoisted_3 = { class: "pagination-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_DefaultPostTable = _resolveComponent("DefaultPostTable")!
  const _component_PostGrid = _resolveComponent("PostGrid")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionTitle, {
      title: "Latest Posts",
      "enable-change-layout": true,
      "enable-period-select": false,
      "query-object": {tags: (this.$route.query.tags) ? this.$route.query.tags : ''},
      "router-link": "/board",
      layout: _ctx.layout,
      onLayoutChanged: _cache[0] || (_cache[0] = (layoutEvent) => this.layout = layoutEvent),
      onPeriodChanged: _ctx.periodChanged
    }, null, 8, ["query-object", "layout", "onPeriodChanged"]),
    (_ctx.layout === 'Table')
      ? (_openBlock(), _createBlock(_component_DefaultPostTable, {
          key: 0,
          posts: _ctx.userPosts
        }, null, 8, ["posts"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PostGrid, { posts: _ctx.userPosts }, null, 8, ["posts"])
    ], 512), [
      [_vShow, _ctx.layout === 'Card']
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.currentPageNumber,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPageNumber) = $event)),
        "page-size": _ctx.currentPageSize,
        "onUpdate:page-size": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPageSize) = $event)),
        "page-sizes": _ctx.pageSizes,
        size: "small",
        layout: "prev, pager, next, sizes",
        total: _ctx.totalPostCount,
        onSizeChange: _ctx.loadCurrentPage,
        onCurrentChange: _ctx.onCurrentPageChange,
        class: "flex-wrap"
      }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "onSizeChange", "onCurrentChange"])
    ])
  ]))
}