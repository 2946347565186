
import {Options, Vue} from "vue-class-component";
import UserEmailBuilder from "@/components/email/UserEmailBuilder.vue";

@Options({components: {UserEmailBuilder}})
export default class CreateEmail extends Vue {
    private preview: String = "";

    public updatePreview(preview: String) {
        this.preview = preview;
    }
}
