
import {Options, Vue} from "vue-class-component";
import {EmailTemplateEntity} from "@/model/entities/EmailTemplateEntity";
import EmailTemplateTable from "@/components/email/EmailTemplateTable.vue";
import {EmailTemplateHttpService} from "@/server/services/EmailTemplateHttpService";
import {DefaultEmailTemplateHttpService} from "@/server/services/impl/DefaultEmailTemplateHttpService";
import EmailModuleItem from "@/components/email/EmailModuleItem.vue";
import {UserEmailEntity} from "@/model/entities/UserEmailEntity";
import {UserEmailHttpService} from "@/server/services/UserEmailHttpService";
import {DefaultUserEmailHttpService} from "@/server/services/impl/DefaulUserEmailHttpService";
import {ElMessage} from "element-plus/es";
import DefaultPostTable from "@/components/post/DefaultPostTable.vue";
import {UrlPostSearchFilterEncoder} from "@/utils/UrlPostSearchFilterEncoder";
import {PostHttpService} from "@/server/services/impl/PostHttpService";
import {PostSearchCriteria} from "@/model/entities/PostSearchCriteria";
import {Page} from "@/model/entities/Page";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {ElTag} from "element-plus";
import PostControlPanel from "@/components/post/PostControlPanel.vue";

@Options({components: {PostControlPanel, ElTag, DefaultPostTable, EmailTemplateTable, EmailModuleItem}, emits: ["update-preview"]})
export default class UserEmailBuilder extends Vue {

    private emailTemplateHttpService: EmailTemplateHttpService = new DefaultEmailTemplateHttpService();
    private userEmailHttpService: UserEmailHttpService = new DefaultUserEmailHttpService();
    private email: UserEmailEntity = new UserEmailEntity();
    private templateDialogVisible: Boolean = false;
    private availableTemplates: EmailTemplateEntity[] = [];

    private urlPostSearchFilterEncoder = new UrlPostSearchFilterEncoder();
    private postService: PostHttpService = new PostHttpService();
    private pageSizes: Number[] = [12, 24, 48]
    private postSearchCriteria: PostSearchCriteria = {page: 1, size: this.pageSizes[1], pattern: "", period: "", listId: null}

    private selectedPosts: Number[] = []
    private postPage: Page<UserPostEntity> = {pageCount: 0, totalItemsCount: 0, content: []};
    private postDialogVisible: Boolean = false;

    public mounted() {
        const emailId = this.$route.query.id || this.$route.params.id;
        if (emailId) {
            this.userEmailHttpService.getUserEmail(Number.parseInt(emailId.toString()))
                .then(email => this.email = email)
                .then(() => this.updatePreview());
        }

        const filter = this.urlPostSearchFilterEncoder.decodeQueryFilter(this.$route.query);
        this.postSearchCriteria.listId = filter.listId;
        if (this.postSearchCriteria.listId) {
          this.postDialogVisible = true
        }

        if(filter.sortBy === "popular") {
          this.postSearchCriteria.popular = true;
        }
    }

    private loadPosts(): Promise<any> {
      return this.postService.loadPosts(this.postSearchCriteria).then(response => this.postPage = response.data);
    }

    public onSelection(posts: UserPostEntity[]): void {
      this.selectedPosts = []
      posts.forEach((post) => this.selectedPosts.push(post.post.id));
    }

    private confirmSelection() {
      this.postDialogVisible = false
      const postModule = this.email.template.modules.find((module) => module.module.dependencies.includes("post"));
      if (!postModule) {
        return
      }

      postModule.dependencyParams.set("post", this.selectedPosts);
      this.updatePreview();
    }

    private loadAvailableTemplates() {
        this.emailTemplateHttpService.loadAllTemplates().then((templates) => this.availableTemplates = templates);
    }

    private handleSelectedTemplate(val: EmailTemplateEntity) {
        this.email.template = val;
        this.templateDialogVisible = false;
        this.updatePreview();
    }

    private updatePreview() {
        this.emailTemplateHttpService.loadTemplatePreview(this.email.template)
            .then(preview => this.$emit('update-preview', preview));
    }

    private saveEmail() {
        this.userEmailHttpService.createUserEmail(this.email)
            .then(() => this.$router.push({name: 'EmailBuilder', query: {active: 'emails'}}))
            .then(() => ElMessage({message: `${this.email.name} successfully created`, type: "success"}))
            .catch((error) => ElMessage({message: error.response.data, type: "error"}));
    }

    private sendEmail() {
        this.userEmailHttpService.sendUserEmail(this.email)
            .then(() => this.$router.push({name: 'EmailBuilder', query: {active: 'emails'}}))
            .then(() => ElMessage({message: `${this.email.name} successfully sent`, type: "success"}))
            .catch((error) => ElMessage({message: error.response.data, type: "error"}));
    }

    private updateEmail() {
        this.userEmailHttpService.updateUserEmail(this.email)
            .then(() => this.$router.push({name: 'EmailBuilder', query: {active: 'emails'}}))
            .then(() => ElMessage({message: `${this.email.name} successfully updated`, type: "success"}))
            .catch((error) => ElMessage({message: error.response.data, type: "error"}));
    }

}
