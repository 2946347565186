
import {Options, Vue} from "vue-class-component";
import SectionTitle from "@/components/commons/SectionTitle.vue";
import {PostHttpService} from "@/server/services/impl/PostHttpService";
import {Page} from "@/model/entities/Page";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import TagFilter from "@/components/commons/TagFilter.vue";
import PostGrid from "@/components/post/PostGrid.vue";
import {PostSearchCriteria} from "@/model/entities/PostSearchCriteria";
import DefaultPostTable from "@/components/post/DefaultPostTable.vue";
import {UrlPostSearchFilterEncoder} from "@/utils/UrlPostSearchFilterEncoder";
import {UrlPostSearchMask} from "@/model/request/UrlPostSearchMask";

@Options({components: {SectionTitle, TagFilter, PostGrid, DefaultPostTable}})
export default class AbstractExtendedPostBoard extends Vue {
    private urlPostSearchFilterEncoder = new UrlPostSearchFilterEncoder();
    private postService: PostHttpService = new PostHttpService();
    private postPage: Page<UserPostEntity> = {pageCount: 0, totalItemsCount: 0, content: []};
    private layout: 'Card' | 'Table' = 'Card'
    private pageSizes: Number[] = [12, 24, 48]
    private postSearchCriteria: PostSearchCriteria = {page: 1, size: this.pageSizes[1], pattern: "", period: "", listId: null}
    private listName: String | null = null;
    //TODO implement Mask to SearchCriteria mapper and remove mask state
    private mask = new UrlPostSearchMask();

    public mounted() {
        const filter = this.urlPostSearchFilterEncoder.decodeQueryFilter(this.$route.query);
        this.mask = filter;
        this.postSearchCriteria.tags = filter.tags;
        this.postSearchCriteria.listId = filter.listId;
        this.postSearchCriteria.recommended = filter.recommended;
        this.postSearchCriteria.bookmarked = filter.bookmarked;
        this.postSearchCriteria.liked = filter.liked;

        if(filter.sortBy === "popular") {
            this.postSearchCriteria.popular = true;
        }
    }

    private tagsUpdated(tags: Array<String>) {
        this.postSearchCriteria.tags = tags;
        this.mask.tags = tags;
        this.updateQuery()
        this.loadPosts();
    }

    private periodChanged(period: String) {
        this.postSearchCriteria.period = period;
        this.loadPosts();
    }

    private sortingChanged(sortBy: 'popular' | 'latest') {
        this.mask.sortBy = sortBy;
        this.postSearchCriteria.popular = sortBy === "popular";
        this.updateQuery()
        this.loadPosts();
    }

    private loadPosts(): Promise<any> {
        return this.postService.loadPosts(this.postSearchCriteria).then(response => this.postPage = response.data);
    }

    private updateQuery() {
        this.$router.push({path: `${this.$route.path}`, query: this.urlPostSearchFilterEncoder.encodeQueryFilter(this.mask)})
    }
}
