import {Tag} from "@/model/entities/Tag";

export class PostEntity {
    id: Number = -1;
    url: String = "";
    previewImageUrl: String = "";
    title: String = "";
    tags: Array<Tag> = [];
    countLikes: Number = 0;
    recommendationStatus: String = "";
    defaultImage: String = "";
    visibilityStatus: String = "";
    creationTimeAgo: String = "";
}