import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-615fb6a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "control-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_form, {
      "label-width": "120px",
      style: {"margin-bottom":"50px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "Name: " }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.listName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.loading]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "info",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$router.push({path: '/dashboard'})))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Discard")
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.onCreate
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}