
import {Options, Vue} from "vue-class-component";
import {ElTable, ElTableColumn} from "element-plus"
import {Emit, Prop} from "vue-property-decorator";
import {PropType, ref} from "vue";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import {server} from "@/server/http-common";

@Options({components: {ElTable, ElTableColumn}})
export default class EmailModuleTable extends Vue {

    private multipleTableRef = ref<InstanceType<typeof ElTable>>();

    @Prop()
    private modules: EmailModuleEntity[] = [];

    @Prop({type: Boolean as PropType<Boolean>})
    private manageable: Boolean = true;

    @Prop({type: Boolean as PropType<Boolean>})
    private selectable: Boolean = true;

    private onEdit(index: number, entity: EmailModuleEntity): void {
        this.$router.push({name: 'EmailModuleDetails', params: {id: entity.id as number}});
    }

    private onDelete(index: number, entity: EmailModuleEntity): void {
        server.delete("/api/v1/emails/modules/" + entity.id)
            .then(response => {
                if (response.status == 200) {
                    this.modules.splice(index, 1);
                }
            });
    }

    private clear(): void {
        this.multipleTableRef.value!.clearSelection()
    }

    @Emit("onModuleSelection")
    public onSelection(modules: EmailModuleEntity[]): EmailModuleEntity[] {
        return modules;
    }

}
