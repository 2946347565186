
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import Post from "@/components/post/Post.vue";

@Options({components: {Post, Carousel, Navigation, Pagination, Slide}})
export default class PostCarousel extends Vue {
    @Prop({type: Object as PropType<Array<UserPostEntity>>})
    private posts: Array<UserPostEntity> = [];

    private settings = {
        itemsToShow: 1,
        snapAlign: 'center',
    };

    private breakpoints = {
        200: {
            itemsToShow: 1,
            snapAlign: 'start',
        },
        600: {
            itemsToShow: 2,
            snapAlign: 'start',
        },
        990: {
            itemsToShow: 3,
            snapAlign: 'start',
        },
        1200: {
            itemsToShow: 4,
            snapAlign: 'start',
        }/*,
        1400: {
            itemsToShow: 5,
            snapAlign: 'start',
        }*/
    };


}
