
import OptionPicker from "@/components/commons/OptionPicker.vue";
import LatestPostBoard from "@/components/post/LatestPostBoard.vue";
import {Options, Vue} from "vue-class-component";
import RecommendedPostBoard from "@/components/post/RecommendedPostBoard.vue";
import PopularPostBoard from "@/components/post/PopularPostBoard.vue";
import CategoryBoard from "@/components/commons/CategoryBoard.vue";
import TagFilter from "@/components/commons/TagFilter.vue";

@Options({
    components: {
        CategoryBoard,
        RecommendedPostBoard,
        PopularPostBoard,
        CompactPostBoard: LatestPostBoard,
        OptionPicker,
        TagFilter
    }
})
export default class Dashboard extends Vue {
    private searchParams: URLSearchParams = new URLSearchParams();

    public onTagsUpdated(tags: Array<String>) {
        this.searchParams = new URLSearchParams({"tags": tags.toString()})
    }
}
