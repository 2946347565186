
import {Options, Vue} from "vue-class-component";
import {reactive, ref} from "vue";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import FormInstance, {ElMessage, ElTable, ElTableColumn} from "element-plus"
import {VueDraggableNext} from 'vue-draggable-next'
import EmailModuleTable from "@/components/email/EmailModuleTable.vue";
import EmailModuleItem from "@/components/email/EmailModuleItem.vue";
import {EmailModuleDescription} from "@/model/entities/EmailModuleDescription";
import {EmailTemplateEntity} from "@/model/entities/EmailTemplateEntity";
import {EmailTemplateHttpService} from "@/server/services/EmailTemplateHttpService";
import {DefaultEmailTemplateHttpService} from "@/server/services/impl/DefaultEmailTemplateHttpService";
import {EmailModuleHttpService} from "@/server/services/EmailModuleHttpService";
import {DefaultEmailModuleHttpService} from "@/server/services/impl/DefaultEmailModuleHttpService";

@Options({components: {EmailModuleItem, VueDraggableNext, ElTable, ElTableColumn, EmailModuleTable}})
export default class EmailTemplateBuilder extends Vue {
    private emailTemplateService: EmailTemplateHttpService = new DefaultEmailTemplateHttpService();
    private emailModulesService: EmailModuleHttpService = new DefaultEmailModuleHttpService();

    private allModules: EmailModuleEntity[] = [];
    private selectedModules: EmailModuleEntity[] = [];
    private moduleDialogVisible: Boolean = false;

    private template = new EmailTemplateEntity();
    private ruleFormRef = ref<typeof FormInstance>();

/*    private emailAddressValidator = (rule: any, value: any, callback: any) => {
        if (ValidatorUtils.isEmailAddress(String(value))) {
            return callback();
        }
        return callback(new Error('Please enter correct email'));
    }*/

    private defaultRules = reactive({
        name: [{required: true, message: 'Please enter template name'}],
/*        email: [
            {required: true, message: 'Please enter delivery email'},
            {validator: this.emailAddressValidator}
        ]*/
    });

    mounted(): void {
        const id = this.$route.params.id
        if (!id) {
            return;
        }

        this.emailTemplateService.loadEmailTemplate(Number.parseInt(id.toString()))
            .then((template) => this.template = template)
            .then(() => console.log(this.template))
            .then(() => this.updatePreview())
            .catch(() => ElMessage({message: "Email template could not be loaded", type: "error"}));
    }

    private openModuleDialog() {
        this.moduleDialogVisible = true;
    }

    private closeModuleDialog() {
        this.moduleDialogVisible = false;
    }

    private loadEmailModules(): void {
        this.emailModulesService.loadModules()
            .then(modules => this.allModules = modules)
            .catch(() => ElMessage({message: "All modules could not be loaded", type: "error"}));
    }

    public onModuleSelection(modules: EmailModuleEntity[]): void {
        this.selectedModules = modules;
    }

    public addModules(): void {
        this.closeModuleDialog();
        const paramsSize = this.template.modules.length;
        this.selectedModules.forEach((module, index) => {
            const moduleDescription = new EmailModuleDescription();
            moduleDescription.module = module;
            moduleDescription.position = index + paramsSize;
            this.template.modules.push(moduleDescription);
        });
        this.selectedModules = [];
        this.updatePreview();
    }

    public removeModule(index: number): void {
        this.template.modules.splice(index, 1);
        this.updatePreview();
    }

    private saveTemplate(ref: any) {
        if (!ref) return;
        ref.validate((valid) => {
            if (!valid) return false;

            this.emailTemplateService.saveTemplate(this.template)
                .then(() => ElMessage({message: 'Email template saved successfully', type: "success"}))
                .then(() => this.$router.push({path: '/emails'}))
                .catch((error) => ElMessage({
                    message: "Email template could not be saved: " + error.response.data,
                    type: "error"
                }));
        });
    }

    private updateTemplate(ref: any) {
        if (!ref) return;
        ref.validate((valid) => {
            if (!valid) return false;

            this.emailTemplateService.updateTemplate(Number.parseInt(this.$route.params.id.toString()), this.template)
                .then(() => ElMessage({message: 'Email template updated successfully', type: "success"}))
                .then(() => this.$router.push({path: '/emails'}))
                .catch(error => {
                    ElMessage({
                        message: "Email template could not be updated: " + error.response.data.error,
                        type: "error"
                    })
                });
        });
    }

    private updateModulePositions() {
        this.template.modules.forEach((description, index) => description.position = index)
        this.updatePreview();
    }

    private updatePreview(): void {
        this.emailTemplateService.loadTemplatePreview(this.template)
            .then(preview => this.$emit('updatePreview', preview))
            .catch(() => ElMessage({message: "Email template preview is not available", type: "error"}))
    }
}
