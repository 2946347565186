
import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";

export default class OptionPicker extends Vue {
  @Prop({type: Boolean})
  private singeActive!: Boolean;

  @Prop({type: Object as PropType<{ value: String, alias: String }[]>})
  public available = [];

  @Prop({type: String})
  public selected: String = "";

  @Prop({type: Object as PropType<String[]>})
  public multipleSelect: String[] = []


  private tagStatusClass(value: String): String {
    const active = "tag-active";
    const disabled = "tag-disabled";

    if (this.singeActive) {
      return (this.selected === value) ? active : disabled;
    }

    return this.multipleSelect.indexOf(value) !== -1 ? active : disabled;
  }

  emitSelected(value: String): void {
    if (this.singeActive) {
      return this.$emit('update:selected', value)
    }

    if (this.multipleSelect.includes(value)) {
      return this.$emit('update:multipleSelect', this.multipleSelect.filter(element => element !== value))
    }

    return this.$emit('update:multipleSelect', this.multipleSelect.concat([value]))
  }

}
