
import {Options, Vue} from "vue-class-component";
import {ElTable, ElTableColumn} from "element-plus";
import {UserEmailEntity} from "@/model/entities/UserEmailEntity";
import {UserEmailHttpService} from "@/server/services/UserEmailHttpService";
import {DefaultUserEmailHttpService} from "@/server/services/impl/DefaulUserEmailHttpService";
import {PropType} from "vue";
import {Prop} from "vue-property-decorator";

@Options({components: {ElTable, ElTableColumn}})
export default class UserEmailTable extends Vue {
    private userEmailHttpService: UserEmailHttpService = new DefaultUserEmailHttpService();
    private userEmails: UserEmailEntity[] = [];

    @Prop({type: Boolean as PropType<Boolean>})
    private manageable: Boolean = false;
    private selectable: Boolean = false;

    public mounted() {
        this.loadUserEmails();
    }

    private onEdit(index: Number, row: UserEmailEntity) {
        this.$router.push({name: 'EmailDetails', params: {id: row.id as number}})
    }

    private onDelete(index: Number, row: UserEmailEntity) {
        this.userEmailHttpService.deleteUserEmail(row.id as number)
            .then(() => this.loadUserEmails())
    }

    private loadUserEmails(): Promise<any> {
        return this.userEmailHttpService.getAllUserEmails().then(emails => this.userEmails = emails);
    }
}
