
    import {Options, Vue} from "vue-class-component";
    import EmailTemplateBuilder from "@/components/email/EmailTemplateBuilder.vue";

    @Options({components: {EmailTemplateBuilder}})
    export default class CreateEmailTemplate extends Vue {

        private preview: String = '';

        private updatePreview(preview: String) {
            this.preview = preview;
        }
    }
