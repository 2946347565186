
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Emit, Prop} from "vue-property-decorator";
import {Tag} from "@/model/entities/Tag";
import {TagHttpService} from "@/server/services/impl/TagHttpService";
import {postEmitter, TAG_SELECTED} from "@/events/PostEventBus";
import Magnify from 'vue-material-design-icons/Magnify.vue';

@Options({components: {Magnify}})
export default class TagsBoard extends Vue {

    @Prop({type: String as PropType<String>})
    private autocompletePlaceholder: String = "Search filter tag";

    //TODO change array to set
    @Prop({type: Object as PropType<Array<Tag>>})
    private dynamicTags!: Array<Tag>

    @Prop({type: Boolean as PropType<Boolean>})
    private closable: Boolean = true;

    @Prop({type: Boolean as PropType<Boolean>})
    private addable: Boolean = true;

    @Prop({type: Function as PropType<Function>})
    private suggestionFunction = () => {
    };

    private tagHttpService: TagHttpService = new TagHttpService();
    private inputValue = '';

    public mounted() {
        postEmitter.on(TAG_SELECTED, (tag) => {
            this.tagHttpService.getTagByName(tag as string)
                .then(response => this.addTag(response.data))
        });
    }

    private onKeyupEnter() {
        this.tagHttpService.getTagByName(this.inputValue)
            .then(response => {
                const tag: Tag = response.data;
                if (tag.id) {
                    this.addTag(tag)
                }
                this.inputValue = "";
            })
    }

    private onInputConfirm(event): void {
        this.addTag(event)
        this.inputValue = '';
        document.getElementById("autocomplete")!.blur();
    }

    @Emit("tag-added")
    private addTag(tag: Tag): Array<Tag> {
        this.removeFromActive(tag.name.toString());
        this.dynamicTags.unshift(tag)
        return this.dynamicTags;
    }

    @Emit("tag-removed")
    private removeTag(name: string): Array<Tag> {
        this.removeFromActive(name);
        return this.dynamicTags;
    }

    removeFromActive(name: string) {
        const elementIndex = this.dynamicTags.findIndex(tag => tag.name === name);
        if (elementIndex !== -1) {
            this.dynamicTags.splice(elementIndex, 1);
        }
    }
}

