
import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {Option} from "@/model/entities/Option";

export default class EmailModulePropertyTable extends Vue {
  @Prop({type: [] as PropType<[]>})
  private fields: [] = [];

  private tableRowClassName = ({row}: { row: { status: String, option: Option }, rowIndex: number }) => {
    if (row.status === "UNSUPPORTED") {
      return 'warning-row';
    }
    if (row.status === "IMPLEMENTED") {
      return 'success-row';
    }
  }

}
