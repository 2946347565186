import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b4b434a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = { class: "text-wrapper" }
const _hoisted_4 = { class: "text-wrapper" }
const _hoisted_5 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_ChannelControlPanel = _resolveComponent("ChannelControlPanel")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.channel.name), 1),
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("URL")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 15 }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.channel.url), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("Selector")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 15 }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.channel.selector), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("Tags")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tag, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.channel.tagName), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ChannelControlPanel, { channel: _ctx.channel }, null, 8, ["channel"])
        ])
      ])
    ]),
    _: 1
  }))
}