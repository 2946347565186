
import {Options, Vue} from "vue-class-component";
import {PostEntity} from "@/model/entities/PostEntity";
import {PostHttpService} from "@/server/services/impl/PostHttpService";
import PostGrid from "@/components/post/PostGrid.vue";
import {ElMessage} from "element-plus";

@Options({components: {PostGrid}})
export default class EditPost extends Vue {
    private postService: PostHttpService = new PostHttpService();

    private loading: Boolean = true;
    private post = new PostEntity();

    public mounted() {
        this.postService.loadPost(Number.parseInt(this.$route.params.id.toString()))
            .then(post => this.post = post)
            .then(() => this.loading = false);
    }

    public onDelete() {
        this.post.visibilityStatus = "NOT_VISIBLE";
        this.onUpdate();
    }

    public onUpdate() {
        this.postService.updatePost(this.post)
            .then(() => ElMessage({message: 'Post successfully updated', type: "success"}));
        this.$router.push({path: '/dashboard'});
    }

}
