import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_TagsBoard = _resolveComponent("TagsBoard")!
  const _component_EmailModuleDependencyPropertyTable = _resolveComponent("EmailModuleDependencyPropertyTable")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_breadcrumb, { style: {"margin-bottom":"15px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb_item, { to: { name: 'EmailBuilder', query: {active: 'modules'} } }, {
          default: _withCtx(() => [
            _createTextVNode(" modules ")
          ]),
          _: 1
        }),
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createTextVNode("create module")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_form, {
      model: _ctx.module,
      rules: _ctx.formValidationRule,
      "label-position": "top",
      ref: "ruleFormRef",
      "label-width": "120px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "Name",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.module.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.name) = $event)),
              placeholder: "Enter module name",
              onInput: _ctx.emitModule,
              "show-word-limit": "",
              maxlength: "50"
            }, null, 8, ["modelValue", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Dependencies" }, {
          default: _withCtx(() => [
            _createVNode(_component_TagsBoard, {
              "suggestion-function": _ctx.getDependenciesSuggestion,
              "autocomplete-placeholder": String('+add entity'),
              onTagAdded: _ctx.updateDependencies,
              onTagRemoved: _ctx.updateDependencies,
              "dynamic-tags": Array.from(this.module.dependencies, dependency => {return {name: dependency}}),
              closable: true,
              addable: true,
              onTagsUpdated: _ctx.loadAvailableAutoProps
            }, null, 8, ["suggestion-function", "autocomplete-placeholder", "onTagAdded", "onTagRemoved", "dynamic-tags", "onTagsUpdated"])
          ]),
          _: 1
        }),
        (_ctx.autoParams.length !== 0)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "Auto params"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EmailModuleDependencyPropertyTable, { fields: _ctx.autoParams }, null, 8, ["fields"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, {
          label: "Content",
          prop: "content"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "Enter html/mjml code",
              modelValue: _ctx.module.content,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.content) = $event)),
              type: "textarea",
              autosize: { minRows: 15, maxRows: 150 },
              onInput: _ctx.parseParams
            }, null, 8, ["modelValue", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              size: "default",
              plain: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveModule(_ctx.ruleFormRef)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.editMode ? "Create new" : "Save module"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}