

import {Options, Vue} from "vue-class-component";
import FormInstance from "element-plus";
import {Option} from "@/model/entities/Option";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import {server} from "@/server/http-common";
import {reactive, ref} from "vue";
import {Emit} from "vue-property-decorator";
import TagsBoard from "@/components/commons/TagsBoard.vue";
import EmailModuleDependencyPropertyTable from "@/components/email/EmailModuleDependencyPropertyTable.vue";
import {TextScanner} from "@/utils/TextScanner";
import {ElMessage} from "element-plus/es";
import {DefaultEmailModuleHttpService} from "@/server/services/impl/DefaultEmailModuleHttpService";
import {Tag} from "@/model/entities/Tag";

//TODO
@Options({components: {EmailModuleDependencyPropertyTable, TagsBoard}})
export default class EmailModuleBuilder extends Vue {
    private textScanner = new TextScanner();
    private emailModuleHttpService = new DefaultEmailModuleHttpService();
    private autoVariablePattern = new RegExp("\\${[ ]*([A-Za-z.]*)}", "g");
    private autoVariableMapperFunction = (s: String) => s.substring(2, s.length - 1).trim();

    private ruleFormRef = ref<typeof FormInstance>();
    private supportedModuleType: String[] = [];
    private availableAutoProps: Option[] = []
    private autoParams = [];
    private editMode: Boolean = false;

    private module = new EmailModuleEntity();

    private formValidationRule = reactive({
        name: [{required: true, message: 'Please enter Module name'}],
        content: [{required: true, message: 'Please enter code'}],
        params: [{validator: this.validateParams}],
        type: [{required: true, message: 'Please select module type'}]
    })

    public loadAvailableAutoProps(dependencies: Array<String>) {
        this.emailModuleHttpService.loadModuleDependenciesProperties(dependencies)
            .then(options => this.availableAutoProps = options)
            .then(() => this.parseParams())
            .then(() => this.emitModule())
    }

    public mounted(): void {
        this.loadModule(this.$route.params.id);
        this.loadSupportedTemplateTypes();
        this.parseParams();
        this.emitModule();
    }

    public loadModule(id: string | string []) {
        if ((typeof (id) === "object") || !id) {
            return;
        }

        this.editMode = true;
        this.emailModuleHttpService.loadModule(Number.parseInt(id))
            .then(module => this.module = module)
            .then(() => this.loadAvailableAutoProps(this.module.dependencies))
            .then(() => this.parseParams);
    }

    private loadSupportedTemplateTypes() {
        server.get("/api/v1/emails/modules/types").then(response => {
            this.supportedModuleType = response.data;
        });
    }

    private validateParams(rule: any, value: any, callback: any) {
        const options: Option[] = Array.from(value);
        for (let option of options) {
            if (!option.description) {
                callback(new Error("Please enter comments for all variables"));
            }
        }
        callback();
    }

    public parseParams() {
        this.parseDependenciesParams();
        this.parseDirectParams();
        this.emitModule();
    }

    public parseDependenciesParams(): void {
        const variables = this.textScanner.getUniqueMatches(this.module.content, this.autoVariablePattern, this.autoVariableMapperFunction);
        const variablesSet = new Set(variables);

        const markedAvailableProps = Array.from(this.availableAutoProps, prop => {
            const propStatus = variablesSet.delete(prop.alias.toString()) ? "IMPLEMENTED" : "NOT IMPLEMENTED";
            return {status: propStatus.toString(), option: prop};
        })

        const unsupportedProps = Array.from(variablesSet, param => {
            return {status: "UNSUPPORTED", option: {alias: param, description: "not supported", value: ""}};
        });

        this.autoParams = markedAvailableProps.concat(unsupportedProps) as any;
    }

    public parseDirectParams(): void {
    }

    private saveModule(ref: any) {
        if (!ref) return;
        ref.validate((valid) => {
            if (valid) {
                server.post("/api/v1/emails/modules", this.module)
                    .then(() => {
                        ElMessage({message: 'Module created', type: "success"});
                        this.$router.push({path: '/emails'});
                    })
                    .catch((error) => {
                        ElMessage({message: error.response.data, type: "error"})
                    });
            } else {
                return false;
            }
        })
    }

    private editModule(ref: any) {
        if (!ref) return;
        ref.validate((valid) => {
            if (valid) {
                server.put("/api/v1/emails/modules/" + this.module.id, this.module)
                    .then(() => {
                        ElMessage({message: 'Module updated', type: "success"});
                        this.$router.push({path: '/emails'});
                    })
                    .catch((error) => {
                        console.error(error);
                        ElMessage({message: error.response.data, type: "error"})
                    });
            } else {
                return false;
            }
        })
    }

    private getDependenciesSuggestion(queryString: string, cb: any) {
        server.get("/api/v1/props/source/all").then(response => {
            cb(Array.from(response.data, suggestion => {
                return {name: suggestion}
            }));
        });
    }

    @Emit("module-updated")
    public emitModule() {
        return this.module;
    }

    private updateDependencies(dependenciesTag: Array<Tag>) {
        this.module.dependencies = Array.from(dependenciesTag, tag => tag.name);
        this.loadAvailableAutoProps(this.module.dependencies);

    }
}
