import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a817c2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-params" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available, (option, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("span", {
          onClick: ($event: any) => (_ctx.emitSelected(option.value)),
          class: _normalizeClass(["tag-class non-selectable", _ctx.tagStatusClass(option.value)])
        }, _toDisplayString(option.alias), 11, _hoisted_2)
      ]))
    }), 128))
  ]))
}