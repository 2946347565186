import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edbc87a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-container" }
const _hoisted_2 = { style: {"margin":"0 10px 0 0"} }
const _hoisted_3 = { class: "control-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_LayoutPicker = _resolveComponent("LayoutPicker")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.routerLink)
        ? (_openBlock(), _createBlock(_component_el_link, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({path: _ctx.routerLink, query: _ctx.queryObject}))),
            type: "primary",
            underline: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(" See more ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.enableSorting)
          ? (_openBlock(), _createBlock(_component_el_select, {
              key: 0,
              size: "small",
              modelValue: _ctx.activeSorting,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeSorting) = $event)),
              style: {"margin-right":"0.5rem"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSorting, (sortBy) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: sortBy,
                    label: 'sort by: ' + sortBy,
                    value: sortBy
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.enablePeriodSelect)
          ? (_openBlock(), _createBlock(_component_el_select, {
              key: 1,
              size: "small",
              modelValue: _ctx.activePeriod,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activePeriod) = $event)),
              style: {"margin-right":"0.5rem"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePeriods, (period) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: "period",
                    label: 'period: ' + period.alias,
                    value: period.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.enableChangeLayout)
          ? (_openBlock(), _createBlock(_component_LayoutPicker, {
              key: 2,
              layout: _ctx.layout,
              onLayoutChanged: _ctx.setLayout
            }, null, 8, ["layout", "onLayoutChanged"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_el_divider, { style: {"margin":"5px 0"} })
  ], 64))
}