import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65efcfa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: tag,
        type: _ctx.active.has(tag.name) ? 'success' : 'info',
        class: "tag non-selectable",
        "disable-transitions": false,
        onClick: ($event: any) => (_ctx.updateSelected(tag))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(tag.name), 1)
        ]),
        _: 2
      }, 1032, ["type", "onClick"]))
    }), 128))
  ]))
}