import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_TagsBoard = _resolveComponent("TagsBoard")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.createSet
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create Set")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_table, { data: _ctx.categories }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, { label: "Name" }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(scope.row.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { label: "Tags" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_TagsBoard, {
                addable: false,
                closable: false,
                "distinct-values": true,
                "autocomplete-placeholder": String('+add entity'),
                "dynamic-tags": scope.row.tags
              }, null, 8, ["autocomplete-placeholder", "dynamic-tags"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Operations",
            align: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: ($event: any) => (_ctx.onEdit(scope.row.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Edit")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                onClick: () => {
                        _ctx.categoryToRemove = scope.row.name
                        _ctx.deleteConfirmationDialogActive = true;
                    }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Delete ")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _createVNode(_component_DeleteDialog, {
      align: "left",
      title: 'Delete confirmation',
      content: 'Are you sure want to delete tag set with name: \'' + _ctx.categoryToRemove + '\'',
      visible: _ctx.deleteConfirmationDialogActive,
      onCancelCallback: () => {this.deleteConfirmationDialogActive = false},
      onDeleteCallback: () => {_ctx.onDelete(_ctx.categoryToRemove)}
    }, null, 8, ["content", "visible", "onCancelCallback", "onDeleteCallback"]),
    _createVNode(_component_el_dialog, {
      "model-value": _ctx.createMode || _ctx.editMode,
      title: "Update set",
      width: "40%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.editMode)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCategory(_ctx.modifiableCategory)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Update")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.createMode)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "primary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createCategory(_ctx.modifiableCategory)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Create")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, { "label-position": "top" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Set name" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.modifiableCategory.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modifiableCategory.name) = $event)),
                  size: "small",
                  maxlength: "20",
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Tags" }, {
              default: _withCtx(() => [
                _createVNode(_component_TagsBoard, {
                  "suggestion-function": _ctx.querySearchTags,
                  addable: true,
                  closable: true,
                  "autocomplete-placeholder": String('+add entity'),
                  "dynamic-tags": _ctx.modifiableCategory.tags
                }, null, 8, ["suggestion-function", "autocomplete-placeholder", "dynamic-tags"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}