import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a5e5cb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "control-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_PostGrid = _resolveComponent("PostGrid")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_form, {
      style: {"margin-bottom":"50px"},
      model: _ctx.post,
      "label-width": "120px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "Title: " }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.post.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.post.title) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Url: " }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.post.url,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.post.url) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Image Url:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.post.previewImageUrl,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.post.previewImageUrl) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])), [
      [_directive_loading, _ctx.loading]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "info",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (this.$router.push({path: '/dashboard'})))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Discard")
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.onUpdate
      }, {
        default: _withCtx(() => [
          _createTextVNode("Update")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, {
        type: "danger",
        onClick: _ctx.onDelete
      }, {
        default: _withCtx(() => [
          _createTextVNode("Delete")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_PostGrid, {
      posts: Array.of({post: this.post, postState: {liked: null, bookmarked: null}})
    }, null, 8, ["posts"])
  ]))
}