export class ValidatorUtils {
    public static isUrl(url: String): Boolean {
        try {
            return Boolean(new URL(url.toString()));
        } catch (e) {
            return false;
        }
    }
    public static isEmailAddress(email: String): Boolean {
        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = email.toLowerCase().match(emailPattern)
        return !!result;
    }
}