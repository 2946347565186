
import {Options, Vue} from "vue-class-component";
import {DoughnutChart} from 'vue-chart-3';
import {LineChart} from "vue-chart-3";
import Chart from 'chart.js/auto'
import {server} from "@/server/http-common";

@Options({components: {DoughnutChart, LineChart, Chart}})
export default class VisitsLineChart extends Vue {

    private testData = {
        labels: [],
        datasets: [
            {
                label: "Post visits",
                data: [],
                borderColor: "#dc6a1e",
                backgroundColor: "#ffffff",
                fill: false
            },
        ],
    };

    private options = {
        scales: {
            yAxes: {
                beginAtZero: true
            }
        }
    }

    public mounted() {
        this.loadDailyStats()
    }

    private loadDailyStats() {
        server.get("/api/v1/statistics/visits").then((response) => {
            const indexedArray = Array.of(...response.data);
            const labels: string[] = [];
            const chartData: string[] = [];

            for (const dataPoint of indexedArray) {
                const key: string = Object.keys(dataPoint)[0]
                labels.push(key);
                chartData.push(dataPoint[key])
            }

            this.testData.datasets[0].data = chartData as any;
            this.testData.labels = labels as any;
        })
    }

}
