import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popular-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_PostCarousel = _resolveComponent("PostCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionTitle, {
      title: "Popular",
      "query-object": {tags: (this.$route.query.tags) ? this.$route.query.tags : '', sortBy: 'popular'},
      "router-link": "/board",
      "enable-change-layout": false,
      "enable-period-select": false,
      onPeriodChanged: _ctx.periodChanged
    }, null, 8, ["query-object", "onPeriodChanged"]),
    _createVNode(_component_PostCarousel, { posts: _ctx.posts }, null, 8, ["posts"])
  ]))
}