
import {ElTable, ElTableColumn} from "element-plus"
import {Emit, Prop} from "vue-property-decorator";
import {Options, Vue} from "vue-class-component";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue';
import {PropType} from "vue";
import {StatisticsHttpService} from "@/server/services/impl/StatisticsHttpService";
import {PostHttpService} from "@/server/services/impl/PostHttpService";

//TODO
@Options({components: {ElTable, ElTableColumn, OpenInNew}})
export default class ApprovedPostTable extends Vue {
    private statisticService: StatisticsHttpService = new StatisticsHttpService();
    private postHttpService: PostHttpService = new PostHttpService();

    @Prop({type: Boolean as PropType<Boolean>})
    private enableResourceLink: Boolean = true;

    private tableData: UserPostEntity[] = [];

    public mounted(): void {
        this.loadPosts();
    }

    @Emit("post-selected")
    public onSelection(userPost: UserPostEntity): UserPostEntity {
        return userPost;
    }

    private loadPosts(): void {
        this.postHttpService.loadPosts({listId: null, page: 1, size: 200, bookmarked: true}).then(response => this.tableData = response.data.content);
    }

    public openPost(post: UserPostEntity): void {
        window.open(post.post.url.toString());
        this.statisticService.saveVisit(post.post.id)
    }
}

