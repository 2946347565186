
import {Options, Vue} from "vue-class-component";
import {ElDialog} from 'element-plus'
import TagsBoard from "@/components/commons/TagsBoard.vue";
import EmailModuleItem from "@/components/email/EmailModuleItem.vue";
import EmailModuleBuilder from "@/components/email/EmailModuleBuilder.vue";
import {EmailModuleDescription} from "@/model/entities/EmailModuleDescription";
import {EmailModuleEntity} from "@/model/entities/EmailModuleEntity";
import {EmailModuleHttpService} from "@/server/services/EmailModuleHttpService";
import {DefaultEmailModuleHttpService} from "@/server/services/impl/DefaultEmailModuleHttpService";

@Options({components: {EmailModuleBuilder, EmailModuleItem, TagsBoard, ElDialog}})
export default class CreateEmailModule extends Vue {
    private emailModuleHttpService: EmailModuleHttpService = new DefaultEmailModuleHttpService();

    private previewString: String = '';
    private moduleDescription = new EmailModuleDescription();

    public emailModuleUpdateHandler(module: EmailModuleEntity) {
        this.moduleDescription.module = module;
        if (this.moduleDescription.module.content) {
            this.renderModulePreview();
        }
    }

    public renderModulePreview(): void {
        this.emailModuleHttpService.loadEmailModulePreview(this.moduleDescription)
            .then(preview => this.previewString = preview)
            .catch(error => {
                if (error.response.data.includes && error.response.data.includes("Not valid HTML/MJML code")) {
                    this.previewString =
                        "<div style=\"height: 150px; display: flex; align-items: center\">\n" +
                            "<span>Your template has syntax errors. Please correct them and try to render it one more time.</span>\n" +
                        "</div>"
                }
            });
    }
}
