
import {Options, Vue} from "vue-class-component";
import {server} from "@/server/http-common";
import TagsBoard from "@/components/commons/TagsBoard.vue"
import {ElButton, ElMessage} from "element-plus";
import {TagSet} from "@/model/entities/TagSet";
import DeleteDialog from "@/components/commons/DeleteDialog.vue";
import {TagSetHttpService} from "@/server/services/impl/TagSetHttpService";
import {TagHttpService} from "@/server/services/impl/TagHttpService";

@Options({components: {TagsBoard, ElButton, DeleteDialog}})
export default class CategoryManagement extends Vue {
    private tagSetHttpService: TagSetHttpService = new TagSetHttpService();
    private tagHttpService: TagHttpService = new TagHttpService();

    private categories: TagSet[] = [];
    private createMode: Boolean = false;
    private editMode: Boolean = false;
    private deleteConfirmationDialogActive = false;
    private modifiableCategory: TagSet = this.getEmptySet();
    private categoryToRemove;

    private createSet() {
        this.createMode = true;
        this.modifiableCategory = this.getEmptySet();
    }

    private closeDialog() {
        this.editMode = false;
        this.createMode = false;
        this.modifiableCategory = this.getEmptySet();
    }

    createCategory(category) {
        if (!this.validateCategory(category)) {
            return
        }

        server.post("/api/v1/sets", category)
            .then((response) => {
                ElMessage({message: `${response.data.name} updated`, type: "success"})
                this.refreshCategories();
                this.editMode = false;
                this.createMode = false;
                this.modifiableCategory = this.getEmptySet();
            })
            .catch(function (error) {
                ElMessage({message: error.response.data, type: "error"});
            });

    }

    private updateCategory(category: TagSet) {
        if (!this.validateCategory(category)) {
            return
        }

        server.put("/api/v1/sets", category)
            .then((response) => {
                ElMessage({message: `${response.data.name} updated`, type: "success"})
                this.refreshCategories();
                this.editMode = false;
                this.modifiableCategory = this.getEmptySet();
            });
    }

    private validateCategory(category: TagSet): Boolean {
        if (category.tags.length < 1) {
            ElMessage({message: `Tags count must be greater than zero`, type: "error"})
            return false;
        }

        if (!category.name) {
            ElMessage({message: `Name should be defined`, type: "error"})
            return false;
        }

        return true;
    }

    private onEdit(name: String) {
        this.editMode = true;
        const selectedCategory = this.categories.filter((category) => category.name === name)[0]
        this.modifiableCategory = new TagSet(selectedCategory.id, selectedCategory.name, [...selectedCategory.tags])
    }

    private onDelete(name: String) {
        this.deleteConfirmationDialogActive = false;
        const selectedCategory = this.categories.filter((category) => category.name === name)[0]
        server.delete("/api/v1/sets/" + selectedCategory.id)
            .then(() => {
                ElMessage({message: 'Category deleted', type: "success"})
                this.refreshCategories();
            })
            .catch((error) => {
                ElMessage({message: error.response.data.error, type: "error"})
            })
    }

    private refreshCategories() {
        this.tagSetHttpService.getAllSets().then(response => this.categories = response.data)
    }


    public mounted(): void {
        this.refreshCategories();
    }

    private getEmptySet() {
        return new TagSet();
    }

    //TODO create separate component for "news tag" selection
    private querySearchTags(queryString: string, cb: any) {
        const selectedTagNamesSet = new Set(Array.from(this.modifiableCategory.tags, tag => tag.name))
        this.tagHttpService.getTagsNamesByQuery(queryString)
            .then(response => cb(response.data.filter(tag => !selectedTagNamesSet.has(tag.name))));
    }
}
